import { MenuItem, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from 'src/types/common/SelectOption';

type ReturnType = {
  selectOptions: JSX.Element | JSX.Element[];
};

export const useSelectOptions = (
  options: ISelectOption[] | [],
  isOptionsSorted?: boolean,
  isDefaultOptionRequired?: boolean,
): ReturnType => {
  const { t } = useTranslation(['common']);

  const selectOptions = useMemo((): JSX.Element[] | JSX.Element => {
    if (!Array.isArray(options) || options.length < 1) {
      return (
        <MenuItem key={'loading'} value={''}>
          {t('loading')}
        </MenuItem>
      );
    }

    // Will be adding 'Please Select...' only if default option (with empty id) is not provided
    // ex; 'All Industries' is replacement of 'Please Select...'
    const isAlreadyDefaultOptionInOptionList = isDefaultOptionRequired
      ? options.find((option) => option.id === '')
      : true;

    const sortedOptions = options.slice().sort((a, b) => {
      if (!a.name || a.name === 'Other') return 1; //sort b before a
      if (!b.name || b.name === 'Other') return -1; //sort a before b

      return a.name.localeCompare(b.name);
    });

    const elements = (isOptionsSorted ? options : sortedOptions).map((option: ISelectOption) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          <Typography variant="body2">{option.name}</Typography>
        </MenuItem>
      );
    });

    if (!isAlreadyDefaultOptionInOptionList) {
      elements.unshift(
        <MenuItem key={'please-select'} value={''}>
          {t('pleaseSelect')}
        </MenuItem>,
      );
    }

    return elements;
  }, [options]);

  return { selectOptions };
};
