import { Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { DeviceType } from 'src/enums/facebook/Device';
import { ServiceType } from 'src/enums/ServiceType';
import { IPlacementByDevice, IPlacementByPlatform } from 'src/types/facebook/FacebookGrade';
import { withSymbol } from '../../grade/table/utils';
import { OrionColors } from 'src/components/theme/orion/common/colors';

interface Props {
  data: IPlacementByDevice | IPlacementByPlatform | undefined;
  type: DataType;
  currencySymbol: string;
}

export enum DataType {
  BY_DEVICE,
  BY_PLATFORM,
}

const PlacementDisplayAttrs = ['MOBILE', 'DESKTOP', 'TABLET'];

export const PlatformOverviewTable = ({ data, type, currencySymbol }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell sx={{ textAlign: 'left', width: '50%' }}>
            {type === DataType.BY_PLATFORM ? (
              <BoldTypography variant="h5">{t('metrics.platformOverview.platformPlacement.heading')}</BoldTypography>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {type === DataType.BY_DEVICE ? (
              <img src={(data as IPlacementByDevice)['MOBILE'].icon} height={40} width={40} alt="MOBILE-ICON" />
            ) : (
              <img src={(data as IPlacementByPlatform)[0].icon} height={40} width={40} alt="PLATFORM-ICON-0" />
            )}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {type === DataType.BY_DEVICE ? (
              <img src={(data as IPlacementByDevice)['DESKTOP'].icon} height={40} width={40} alt="DESKTOP-ICON" />
            ) : (
              <img src={(data as IPlacementByPlatform)[1].icon} height={40} width={40} alt="PLATFORM-ICON-1" />
            )}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {type === DataType.BY_DEVICE ? (
              <img src={(data as IPlacementByDevice)['TABLET'].icon} height={40} width={40} alt="TABLET-ICON" />
            ) : (
              <img src={(data as IPlacementByPlatform)[2].icon} height={40} width={40} alt="PLATFORM-ICON-2" />
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell sx={{ textAlign: 'left' }}>{t('metrics.platformOverview.platformPlacement.spend')}</TableCell>
          <>
            {PlacementDisplayAttrs.map((attr, index: number) => (
              <TableCell sx={{ textAlign: 'center' }} key={index}>
                {type === DataType.BY_DEVICE
                  ? withSymbol((data as IPlacementByDevice)[attr as DeviceType].spend ?? 0, currencySymbol, 'Not Used')
                  : withSymbol((data as IPlacementByPlatform)[index].spend ?? 0, currencySymbol, 'Not Used')}
              </TableCell>
            ))}
          </>
        </TableRow>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell sx={{ textAlign: 'left' }}>{t('metrics.platformOverview.platformPlacement.reach')}</TableCell>
          <>
            {PlacementDisplayAttrs.map((attr, index: number) => (
              <TableCell sx={{ textAlign: 'center' }} key={attr}>
                {type === DataType.BY_DEVICE
                  ? (data as IPlacementByDevice)[attr as DeviceType].reach
                    ? (data as IPlacementByDevice)[attr as DeviceType].reach?.toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 0 },
                      )
                    : ((data as IPlacementByDevice)[attr as DeviceType].spend as number) > 0
                      ? 0
                      : 'Not Used'
                  : (data as IPlacementByPlatform)[index].reach
                    ? (data as IPlacementByPlatform)[index].reach?.toLocaleString(process.env.REACT_APP_LOCALE, {
                        minimumFractionDigits: 0,
                      })
                    : ((data as IPlacementByPlatform)[index].spend as number) > 0
                      ? 0
                      : 'Not Used'}
              </TableCell>
            ))}
          </>
        </TableRow>
        <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
          <TableCell sx={{ textAlign: 'left' }}>{t('metrics.platformOverview.platformPlacement.ctr')}</TableCell>
          <>
            {PlacementDisplayAttrs.map((attr, index: number) => (
              <TableCell sx={{ textAlign: 'center', whiteSpace: 'pre' }} key={attr}>
                {type === DataType.BY_DEVICE
                  ? (data as IPlacementByDevice)[attr as DeviceType].ctr
                    ? (((data as IPlacementByDevice)[attr as DeviceType].ctr as number) / 100).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 2, style: 'percent' },
                      )
                    : ((data as IPlacementByDevice)[attr as DeviceType].spend as number) > 0
                      ? '0%'
                      : 'Not Used'
                  : (data as IPlacementByPlatform)[index].ctr
                    ? (((data as IPlacementByPlatform)[index].ctr as number) / 100).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 2, style: 'percent' },
                      )
                    : ((data as IPlacementByPlatform)[index].spend as number) > 0
                      ? '0%'
                      : 'Not Used'}
              </TableCell>
            ))}
          </>
        </TableRow>
      </TableBody>
    </Table>
  );
};
