import { useTheme, styled, Typography, useMediaQuery, Divider } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FlexBoxColumn } from './FlexBoxColumn';
import LockSVG from 'src/assets/lock.svg';
import { ResponsiveRow } from './ResponsiveRow';
import { FlexBoxRow } from './FlexBoxRow';
import { StyledLink } from './styled/StyledLink';
import { BoldTypography } from './styled/BoldTypography';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { StyledPageContent } from './styled/StyledPageElements';

const StyledIcon = styled('img')<{ badgesize?: number }>`
  width: ${({ badgesize }) => badgesize ?? 80}px;
  height: ${({ badgesize }) => badgesize ?? 77}px;
`;

type Props = {
  badgeSVG: string;
  i18nNameSpace: string;
  iconHref?: string;
  badgesize?: number;
};

const isUrl = (string: string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const BadgeFooter = ({ badgeSVG, badgesize, i18nNameSpace, iconHref }: Props): JSX.Element => {
  const { t } = useTranslation([i18nNameSpace, 'common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const actualIconHref = iconHref && (isUrl(iconHref) ? iconHref : t(iconHref));

  const onIconClick = () => {
    if (!actualIconHref) return;
    window.open(actualIconHref, '_blank');
  };

  return (
    <FlexBoxRow sx={{ color: 'common.white', backgroundColor: 'common.black' }}>
      <StyledPageContent>
        <ResponsiveRow breakpoint={'sm'}>
          <FlexBoxColumn sx={{ alignItems: 'flex-start', py: 3, gap: 1 }}>
            <FlexBoxRow justifyContent="flex-start" gap={1}>
              <img src={LockSVG} alt="Safe & Secure" />
              <BoldTypography variant="h6">{t(`${i18nNameSpace}:error.footer.second_header`)}</BoldTypography>
            </FlexBoxRow>
            <Typography variant="caption" sx={{ lineHeight: '15px' }}>
              <Trans
                t={t}
                i18nKey={`${i18nNameSpace}:error.footer.second_content`}
                components={[
                  <StyledLink key="privacy_policy" href={t('footer.link_privacy', { ns: 'common' })} target="_blank" />,
                  <StyledLink key="terms_conditions" href={t('footer.link_terms', { ns: 'common' })} target="_blank" />,
                ]}
              />
            </Typography>
          </FlexBoxColumn>
          <Divider
            orientation={isMobile ? 'horizontal' : 'vertical'}
            flexItem={true}
            sx={{ minHeight: '100%', borderColor: OrionColors.cream.blocking, mx: isMobile ? 0 : 3.25 }}
          />
          <FlexBoxRow sx={{ width: '100%', justifyContent: isMobile ? 'flex-start' : 'flex-end', py: 3 }}>
            <StyledIcon
              sx={{ cursor: iconHref && 'pointer' }}
              onClick={onIconClick}
              badgesize={badgesize}
              src={badgeSVG}
              alt="Premier Partner"
            />
          </FlexBoxRow>
        </ResponsiveRow>
      </StyledPageContent>
    </FlexBoxRow>
  );
};

export default BadgeFooter;
