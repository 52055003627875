import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ScoreName } from 'src/enums/ScoreName';
import { useOverallGrade } from '../overall/useOverallGrade';
import { GradeUtils } from 'src/utils/GradeUtils';

export interface ReportLinksData {
  adtype: GoogleAdType | null;
  pathName: string;
  url?: string;
  scoreName?: ScoreName | undefined;
  scoreValue?: number | string;
}

const INITIAL_LINKS_DATA = [
  {
    adtype: GoogleAdType.SEARCH,
    pathName: GoogleAdType.SEARCH as string,
  },
  {
    adtype: GoogleAdType.DISPLAY,
    pathName: GoogleAdType.DISPLAY as string,
  },
  {
    adtype: GoogleAdType.SHOPPING,
    pathName: GoogleAdType.SHOPPING as string,
  },
];

export const useReportLinksData = (): ReportLinksData[] => {
  const [reportLinksData, setReportLinksData] = useState<ReportLinksData[]>(INITIAL_LINKS_DATA);
  const { pathname } = useLocation();
  const { googleAdType } = useContext(GoogleAdTypeContext);
  const { UUID } = useParams();
  const { overallGrade, b4Shopping } = useOverallGrade(UUID);
  const currentReportLinkData = reportLinksData.find((dataItem) => dataItem.adtype === googleAdType);
  const currentReportPathName = currentReportLinkData?.pathName ?? 'report';

  useEffect(() => {
    if (overallGrade) {
      setReportLinksData([
        ...INITIAL_LINKS_DATA.filter((linkData) => {
          const isNotCurrentAdType = linkData.adtype !== googleAdType;
          const excludeShopping = b4Shopping && linkData.adtype === GoogleAdType.SHOPPING;
          return isNotCurrentAdType && !excludeShopping;
        })
          .filter((linkData) => linkData.adtype !== googleAdType)
          .reduce(
            (result: ReportLinksData[], linkData: ReportLinksData) => {
              result = [
                ...result,
                {
                  ...linkData,
                  url: pathname.replace(currentReportPathName, linkData.pathName),
                  scoreName: overallGrade[
                    `${linkData.adtype}Scores` as 'searchScores' | 'displayScores' | 'shoppingScores'
                  ]
                    ? GradeUtils.getScoreNameFromNumber(
                        overallGrade[`${linkData.adtype}Scores` as 'searchScores' | 'displayScores' | 'shoppingScores']!
                          .overallScore,
                      )
                    : undefined,
                  scoreValue:
                    (overallGrade[`${linkData.adtype}Scores` as 'searchScores' | 'displayScores' | 'shoppingScores']
                      ?.spend ?? 0) > 0
                      ? (overallGrade[`${linkData.adtype}Scores` as 'searchScores' | 'displayScores' | 'shoppingScores']
                          ?.overallScore ?? 0)
                      : '-',
                },
              ];
              return result;
            },
            [
              {
                url: pathname.replace(currentReportPathName, 'report'),
                scoreName: GradeUtils.getScoreNameFromNumber(overallGrade.overallScore),
                scoreValue: overallGrade.overallScore,
                adtype: GoogleAdType.OVERALL,
                pathName: 'report',
              },
            ],
          ),
      ]);
    }
  }, [googleAdType, overallGrade, pathname, b4Shopping]);

  return reportLinksData;
};
