import { useGGContext } from 'src/components/providers/googleAds';
import { useFAContext } from 'src/components/providers/facebookAds';
import { NavBar } from './NavBar';

export const NavBarContainerGoogleAds = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const accountName = useGGContext().googleAdsAccount?.accountName; // eslint-disable-line
  return (
    <>
      <NavBar accountName={accountName} />
      {children}
    </>
  );
};

export const NavBarContainerFacebook = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const accountName = useFAContext().facebookAdsAccount?.accountName; // eslint-disable-line
  return (
    <>
      <NavBar accountName={accountName} />
      {children}
    </>
  );
};
