import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
  dialogClasses,
  dialogContentClasses,
} from '@mui/material';
import { FlexBoxRow } from '../FlexBoxRow';
import { GradientLine } from '../GradientLine';
import { Close } from '@mui/icons-material';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { FlexBoxColumn } from '../FlexBoxColumn';
import Backdrop from '../Backdrop';
import styled from 'styled-components';

export const BaseDialog = styled(Dialog)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .${dialogClasses.paper} {
    margin: 0;
    padding: 24px;
  }

  .${dialogContentClasses.root} {
    padding: 28px 48px;
    box-shadow: none;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .${dialogClasses.paper} {
      width: calc(100vw - 32px - 48px);
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    .${dialogClasses.paper} {
      width: 550px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    .${dialogClasses.paper} {
      width: 750px;
    }
  }
`;

interface IProps extends DialogProps {
  children: JSX.Element;
  headertext: string;
  onClose: React.MouseEventHandler;
}

export const StyledDialog = ({ ...props }: IProps): JSX.Element => {
  return (
    <BaseDialog {...props} BackdropComponent={Backdrop} maxWidth={'md'}>
      <FlexBoxColumn sx={{ p: 0, pb: 3.5 }}>
        <FlexBoxRow sx={{ width: '100%', paddingBottom: 3 }}>
          <Box sx={{ flex: '1 0 0', textAlign: 'center', paddingX: 3 }}>
            <Typography variant="h4" fontWeight={FontWeight.SemiBold}>
              {props.headertext}
            </Typography>
          </Box>
          <IconButton onClick={props.onClose} sx={{ color: 'common.black' }}>
            <Close />
          </IconButton>
        </FlexBoxRow>
        <GradientLine />
      </FlexBoxColumn>
      <DialogContent dividers={false}>{props.children}</DialogContent>
    </BaseDialog>
  );
};
