import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ServiceType } from 'src/enums/ServiceType';
import styled from 'styled-components';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';

type Props = {
  ctr: number;
  impressions: number;
  clicks: number;
};

const StyledColumn = styled(FlexBoxColumn)`
  flex: 1 0 0;
  padding: ${({ theme }) => theme.spacing(1)};
  border: ${({ theme }) => theme.spacing(0.125)} solid ${OrionColors.gray.neutral};
  gap: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.breakpoints.down(600)} {
    align-self: stretch;
    padding: ${({ theme }) => theme.spacing(2)};
    justify-content: space-between;
  }
`;

export const AdScore = ({ ctr, impressions, clicks }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <FlexBoxRow width="100%" gap={1} sx={{ justifyContent: 'space-around', alignItems: 'stretch' }}>
      <StyledColumn sx={{ textAlign: 'center' }}>
        <Typography variant="overline" textTransform={'none'}>
          {isSmallerScreen ? t('metrics.textAds.ctrShort') : t('metrics.textAds.ctrLong')}
        </Typography>
        <BoldTypography variant="h3">
          {ctr > 0
            ? ctr.toLocaleString(process.env.LOCALE, {
                style: 'percent',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : ctr.toLocaleString(process.env.LOCALE, { style: 'percent' })}
        </BoldTypography>
      </StyledColumn>
      <StyledColumn sx={{ textAlign: 'center' }}>
        <Typography variant="overline" textTransform={'none'}>
          {t('metrics.textAds.impressions')}
        </Typography>
        <BoldTypography variant="h3">
          {GradeUtils.roundoff(impressions, 2).toLocaleString(process.env.LOCALE)}
        </BoldTypography>
      </StyledColumn>
      <StyledColumn sx={{ textAlign: 'center' }}>
        <Typography variant="overline" textTransform={'none'}>
          {t('metrics.textAds.clicks')}
        </Typography>
        <BoldTypography variant="h3">
          {GradeUtils.roundoff(clicks, 0).toLocaleString(process.env.LOCALE)}
        </BoldTypography>
      </StyledColumn>
    </FlexBoxRow>
  );
};
