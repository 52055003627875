import { Box, styled, SvgIcon, Tooltip } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledIcon = styled(SvgIcon)<{ component: typeof HelpOutlineIcon }>`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: -${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
`;

type Props = {
  title: string;
  children?: JSX.Element;
  color?: string;
  placement?: 'top' | 'bottom';
};

export const HelperTooltip = ({ title, children, color, placement }: Props): JSX.Element => {
  return (
    <Tooltip
      enterTouchDelay={0}
      title={title}
      color={color ?? OrionColors.gray.mid}
      placement={placement ?? 'bottom'}
      arrow
    >
      <Box component="span" sx={{ whiteSpace: 'pre' }}>
        {children}
        <StyledIcon component={HelpOutlineIcon} inheritViewBox />
      </Box>
    </Tooltip>
  );
};
