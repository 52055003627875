import { NavBarContainerFacebook } from 'src/components/common/NavBarContainer';
import { GridType } from 'src/enums/GridType';
import { PageContentFB } from './PageContent';
import { useParams } from 'react-router-dom';
import { useFacebookGrade } from 'src/hooks/facebook/useFacebookGrade';
import { useEffect } from 'react';
import { useFAContext } from 'src/components/providers/facebookAds';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FooterContent } from 'src/components/googleAds/page/Page';

type Props = {
  gridType: GridType.TAB | GridType.FORM;
  children: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
};

export const FacebookAdsPage = (props: Props): JSX.Element => {
  const { children, additionalFooter, gridType } = props;
  const context = useFAContext();
  const { UUID } = useParams();
  const { grade } = useFacebookGrade(UUID);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  useEffect(() => {
    if (grade) {
      context.setIsNewReport(false);
    }
  }, [grade]);

  return (
    <NavBarContainerFacebook>
      <>
        <PageContentFB gridType={props.gridType}>{children}</PageContentFB>
        <FlexBoxColumn>
          <FooterContent additionalFooter={additionalFooter} gridType={gridType} isMobile={isMobile} />
        </FlexBoxColumn>
      </>
    </NavBarContainerFacebook>
  );
};
