import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';

export enum GoogleAdsScoreSection {
  WASTED_SPEND = 'Wasted Spend',
  QUALITY_SCORE = 'Quality Score', // Google Ads Quality Score
  IMPRESSION_SHARE = 'Impression Share',
  ACCOUNT_ACTIVITY = 'Account Activity',
}

export enum GoogleDisplayScoreSection {
  STATUS = 'Display Ad Types',
  PLACEMENT = 'Display Ad Placements',
  AUDIENCE_PERFORMANCE = 'Audience Performance',
}

export enum GoogleShoppingScoreSection {
  WASTED_SPEND = 'Wasted Spend',
  ACCOUNT_STRUCTURE = 'Account Structure',
  CAMPAIGN_RANKINGS = 'Campaign Rankings',
}

export type GoogleScoreSectionType = GoogleAdsScoreSection | GoogleDisplayScoreSection | GoogleShoppingScoreSection;
export type ScoreSectionType = GoogleScoreSectionType | FacebookAdsScoreSection;

export type GoogleAdsScoreSectionTypeKey = keyof typeof GoogleAdsScoreSection;
