import { SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ICategory } from 'src/types/common/Category';
import { ISubCategory } from 'src/types/common/SubCategory';
import { Logger } from 'src/utils/Logger';
import { useSubCategories } from 'src/hooks/common/useSubCategories';
import { useCategories } from 'src/hooks/common/useCategories';
import { IGGContext } from 'src/types/google/GGContext';
import { IFacebookContext } from 'src/types/facebook/FacebookContext';
import { IAdGeneratorContext } from 'src/components/providers/adGenerator';

type ReturnType = {
  onCategoryChange: (event: SelectChangeEvent<string>) => void;
  categories: ICategory[];
  subCategories: ISubCategory[];
};

export const useManageCategories = (context: IGGContext | IFacebookContext | IAdGeneratorContext): ReturnType => {
  const [categories, setCategories] = useState([] as ICategory[]);
  const [subCategories, setSubCategories] = useState([] as ISubCategory[]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const categoriesResponse = useCategories();
  const subCategoriesResponse = useSubCategories(selectedCategory);

  const onCategoryChange = useCallback(
    (event: SelectChangeEvent<string>) => setSelectedCategory(+event.target.value),
    [],
  );

  useEffect(() => {
    if (
      (context as IAdGeneratorContext).industryDetails && // eslint-disable-line
      (context as IAdGeneratorContext).industryDetails.categoryId // eslint-disable-line
    ) {
      setSelectedCategory(+(context as IAdGeneratorContext).industryDetails.categoryId);
    } else if (
      !(context as IAdGeneratorContext).industryDetails && // eslint-disable-line
      (context as IGGContext | IFacebookContext).businessDetails && // eslint-disable-line
      (context as IGGContext | IFacebookContext).businessDetails.categoryId
    ) {
      setSelectedCategory((context as IGGContext | IFacebookContext).businessDetails.categoryId!);
    }
  }, []);

  useEffect(() => {
    Logger.trace('useEffect:categories', { categoriesResponse, subCategoriesResponse }, 'FormStepTwo');

    if (categoriesResponse.categories) {
      setCategories(categoriesResponse.categories);
    }

    if (subCategoriesResponse.data?.subcategories) {
      setSubCategories(subCategoriesResponse.data.subcategories);
    }
  }, [categoriesResponse, subCategoriesResponse]);

  return { onCategoryChange, categories, subCategories };
};
