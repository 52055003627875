import { useCallback, useContext, useEffect, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { useGGContext } from 'src/components/providers/googleAds';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { IBusinessDetails } from 'src/types/common/BusinessDetails';
import { IGradeProcess } from 'src/types/google/GoogleProcess';
import { postGoogleProcess } from 'src/utils/helpers';
import { Logger } from 'src/utils/Logger';

export interface IFormStepTwo {
  businessName: string;
  categoryId: string;
  subcategoryId: string;
}

const FormStepTwoFields = ['businessName', 'categoryId', 'subCategoryId'];

type ReturnType = {
  disabled: boolean;
  onSubmit: () => void;
};

export const useSubmitForm = (getValues: UseFormGetValues<IFormStepTwo>): ReturnType => {
  const [disabled, setDisabled] = useState(false);
  const [reportID, setReportID] = useState<string | null>(null);
  const navigate = useNavigate();
  const context = useGGContext();
  const { setGoogleAdType } = useContext(GoogleAdTypeContext);
  const [searchParams] = useSearchParams();
  const cid = searchParams.get('cid');

  const onSubmit = useCallback(() => {
    setDisabled(true);

    const businessDetails = FormStepTwoFields.reduce(
      (result, fieldName) => ({
        ...result,
        [fieldName]: getValues(fieldName as keyof IFormStepTwo),
      }),
      {},
    ) as IBusinessDetails;

    Logger.trace('onSubmit:businessDetails', { businessDetails }, 'FormStepTwo');

    context.setBusinessDetails({ ...businessDetails });

    postGoogleProcess({
      auth: context.auth,
      customerId: context.googleAdsAccount.accountId,
      ...context.userDetails,
      ...businessDetails,
    }).then((response: IGradeProcess) => {
      Logger.success('onSubmit:GoogleProcess:success', { response }, 'FormStepTwo');
      setReportID(response.uuid);
    });
  }, [context, getValues]);

  useEffect(() => {
    Logger.trace('useEffect:reportID', { reportID }, 'FormStepTwo');

    if (reportID) {
      if (cid) navigate(`/google-grader/report/${reportID}?cid=${cid}`);
      else navigate(`/google-grader/report/${reportID}`);
      setGoogleAdType(GoogleAdType.OVERALL);
    }
  }, [reportID]);

  return { disabled, onSubmit };
};
