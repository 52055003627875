import { StyledAutoComplete } from './StyledAutocomplete';
import { useFormContext, Controller } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useDebounce } from './useDebounce';
import { ILocation } from 'src/types/common/Location';
import { useGeoLocations } from 'src/hooks/google/useGeoLocations';
import { FormatCanonicalName } from 'src/utils/helpers';

import styled from 'styled-components';
import ArrowDown from 'src/assets/ArrowDown.svg';

const TargetTypeText = styled.span`
  font-size: 0.75rem;
  font-weight: lighter;
  padding-left: 8px;
  text-transform: lowercase;
`;

type Props = {
  name: string;
  size?: 'small' | 'medium';
};

export const LocationAutoComplete: React.FC<Props> = ({ name, size }) => {
  const { control } = useFormContext();
  const [inputValue, setInputValue] = useState('United States');
  const debouncedSearch = useDebounce(inputValue);
  const { locations } = useGeoLocations(debouncedSearch);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <StyledAutoComplete
          aria-label={name}
          value={value as ILocation}
          onChange={(event, item) => onChange(item)}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          options={locations ?? []}
          getOptionLabel={(opt) => {
            const location = opt as ILocation;
            return location.canonicalName ? FormatCanonicalName(location.canonicalName) : location.name;
          }}
          isOptionEqualToValue={(option, value) =>
            (value as ILocation).canonicalName
              ? (option as ILocation).canonicalName === (value as ILocation).canonicalName
              : (option as ILocation).name === (value as ILocation).name
          }
          fullWidth={true}
          size={size ?? 'medium'}
          renderOption={(props, option) => {
            const { name, canonicalName, targetType } = option as ILocation;
            return (
              <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                {canonicalName ? FormatCanonicalName(canonicalName) : name}&nbsp;
                <TargetTypeText>{targetType ? targetType.toLowerCase() : ''}</TargetTypeText>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={ArrowDown} alt="dropdown arrow" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
