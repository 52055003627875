import { ScoreName } from 'src/enums/ScoreName';
import { Typography } from '@mui/material';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { GradeUtils } from 'src/utils/GradeUtils';

type Props = {
  score: number | undefined;
  scoreName: ScoreName;
  text: string;
  withIcon: boolean;
};

const WithStartPadding = (score: number): string => {
  switch (true) {
    case score > -1 && score <= 9:
      return String(score).padStart(5);
    case score > 9 && score < 100:
      return String(score).padStart(4);
    default:
      return String(score).padStart(3);
  }
};

export const FindingsRow = ({ score, scoreName, text, withIcon }: Props): JSX.Element => {
  const iconColored = GradeUtils.getIconColored(scoreName as ScoreName);

  return (
    <>
      {score !== undefined && (
        <FlexBoxRow sx={{ gap: 1, width: '100%', justifyContent: 'flex-start' }}>
          {withIcon && <img style={{ width: 20, height: 16 }} src={iconColored} alt="ICON" />}
          <Typography variant="body1" fontWeight={'fontWeightBold'} sx={{ whiteSpace: 'pre' }}>
            {WithStartPadding(score)}
          </Typography>
          <Typography variant="body1" fontWeight={'fontWeightRegular'}>
            {text}
          </Typography>
        </FlexBoxRow>
      )}
    </>
  );
};
