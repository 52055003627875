import { useContext, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEmailReport } from 'src/hooks/common/useEmailReport';
import { useParams } from 'react-router-dom';
import { IEmailReportResponse } from 'src/types/common/IEmailReport';
import { ServiceContext } from 'src/components/providers/service';
import { useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import * as Yup from 'yup';

type Props = {
  open: boolean;
  onClose: () => void;
  onResponse: (response: IEmailReportResponse) => void;
};

type ReturnType = {
  onSubmit: () => Promise<void>;
  methods: UseFormReturn<
    {
      shareEmail: string;
      fromEmail: string;
      messageText: string;
    },
    Record<string, unknown>
  >;
};

const useShareModal = ({ open, onClose, onResponse }: Props): ReturnType => {
  const { UUID } = useParams();
  const [service] = useContext(ServiceContext);
  const { grade } = { grade: useOverallGrade(UUID).overallGrade };
  const validationSchema = Yup.object().shape({
    shareEmail: Yup.string().email('Email is invalid').required('Email is required'),
    fromEmail: Yup.string().email('From email is invalid').required('From email is required'),
    messageText: Yup.string().max(256, 'Message can not exceed 256 characters'),
  });

  const defaultInputValues = {
    shareEmail: '',
    fromEmail: '',
    messageText: '',
  };

  const { ...methods } = useForm({
    defaultValues: defaultInputValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { sendEmail, response } = useEmailReport({ reportTo: service, reportFrom: service, message: service });

  const onSubmit = methods.handleSubmit((data) => {
    onClose();
    sendEmail({
      shareEmail: data.shareEmail,
      fromEmail: data.fromEmail,
      messageText: data.messageText,
      uuid: UUID,
    });
  });

  useEffect(() => {
    if (grade) {
      const gradeEmail = grade.email;
      if (gradeEmail) {
        methods.setValue('fromEmail', gradeEmail);
      }
    }
  }, [grade]);

  useEffect(() => {
    if (open) {
      methods.clearErrors();
    }
  }, [open]);

  useEffect(() => {
    if (!response) return;

    onResponse(response);
  }, [response]);

  return { methods, onSubmit };
};

export default useShareModal;
