import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { App } from './App';
import './index.scss';
import './i18n';
import { HelmetProvider } from 'react-helmet-async';
import { Logger } from 'src/utils/Logger';

const GTM_IDS: Record<string, string> = {
  default: 'GTM-W7WSGJ3',
  copyTool: 'GTM-W9SLPDB',
};

const getGTMId = (): string => {
  return window.location.pathname.startsWith('/copy-tool') ? GTM_IDS.copyTool : GTM_IDS.default;
};

if (process.env.REACT_APP_INSTANCE !== 'dev') {
  const gtmId = getGTMId();
  if (gtmId) {
    TagManager.initialize({ gtmId });
    Logger.info('TagManagerInitialized', { gtmId }, 'index.tsx');
  }
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
