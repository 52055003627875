import { Typography, useMediaQuery } from '@mui/material';
import { StyledCardWithPadding, StyledCardWithPaddingForErrors } from './styled/StyledCard';
import { FlexBoxColumn } from './FlexBoxColumn';
import { OrionTheme } from '../theme/orion/Theme';

type Props = {
  title: string;
  body: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
};

export const ContentCard = ({ title, body, children }: Props): JSX.Element => {
  return (
    <StyledCardWithPadding sx={{ gap: 5 }}>
      <FlexBoxColumn sx={{ gap: 2 }}>
        <Typography textAlign="center" variant="h3">
          {title}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {body}
        </Typography>
      </FlexBoxColumn>
      {children}
    </StyledCardWithPadding>
  );
};

const FlexBoxColumnPB = (isMobile: boolean): number => {
  if (isMobile) return 3;
  return 5;
};

export const ContentCardForErrors = ({ title, body, children }: Props): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  return (
    <StyledCardWithPaddingForErrors>
      <FlexBoxColumn sx={{ gap: isMobile ? 1 : 2, pb: children && FlexBoxColumnPB(isMobile) }}>
        <Typography textAlign="center" variant="h3">
          {title}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {body}
        </Typography>
      </FlexBoxColumn>
      {children}
    </StyledCardWithPaddingForErrors>
  );
};
