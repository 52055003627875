import { Icon, Select } from '@mui/material';
import styled from 'styled-components';
import ArrowDown from 'src/assets/ArrowDown.svg';

const StyledSelect = styled(Select)`
  .MuiSelect-nativeInput {
    display: none;
  }
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
`;

StyledSelect.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  IconComponent: (props) => (
    <Icon {...props}>
      <img src={ArrowDown} alt="Arrow-Down" />
    </Icon>
  ),
};

export default StyledSelect;
