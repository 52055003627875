import { BusinessType, BusinessTypeKey } from '../../../enums/BusinessType';
import { ITranslation } from 'src/types/common/Translation';
import * as Yup from 'yup';
import styled from 'styled-components';

const PhoneRegExp = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im;

export interface IFormStepOne {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  businessType: BusinessType | string;
}

export const FormStepOneFields = ['firstName', 'lastName', 'phoneNumber', 'businessType'];

export const FormStepOneStyledForm = styled('form')`
  .& muilist-root: {
    display: none;
  }
`;

export const getFormStepOneValidatitonSchema = ({ t }: ITranslation): Yup.AnyObjectSchema => {
  return Yup.object().shape(
    {
      firstName: Yup.string().required(t('errors.required')),
      lastName: Yup.string().required(t('errors.required')),
      phoneNumber: Yup.string().matches(PhoneRegExp, t('errors.phoneNotValid')),
      businessType: Yup.string()
        .nullable()
        .notRequired()
        .when('businessType', {
          is: (value: string) => value,
          then: (rule) => rule.oneOf(Object.keys(BusinessType)),
        }),
    },
    [['businessType', 'businessType']],
  );
};

export const BusinessTypesOptions = (Object.keys(BusinessType) as BusinessTypeKey[]).map((businessTypeKey) => ({
  id: businessTypeKey,
  name: BusinessType[businessTypeKey],
}));
