import useSWR, { KeyedMutator } from 'swr';
import { IErrorResponse } from 'src/types/common/Error';
import { IFacebookGrade } from 'src/types/facebook/FacebookGrade';
import { IFacebookGradeProcessWithStatus } from 'src/types/facebook/FacebookProcess';
import { GradeProcessorStatus } from '../overall/useProcessGrade';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type ReturnType = {
  grade: IFacebookGrade | undefined;
  error: IErrorResponse | undefined;
  loading: boolean;
};

const DELAY = 1000;

let timeoutInterval: NodeJS.Timeout | undefined;

const HandleSuccessFn = (
  data: IFacebookGradeProcessWithStatus,
  mutate: KeyedMutator<IFacebookGradeProcessWithStatus>,
  navigate: NavigateFunction,
) => {
  switch (data.status) {
    case GradeProcessorStatus.PENDING:
      if (!timeoutInterval) timeoutInterval = setInterval(mutate, DELAY);
      break;
    case GradeProcessorStatus.COMPLETE:
      if (timeoutInterval) clearInterval(timeoutInterval);
      break;
    case GradeProcessorStatus.ERROR:
      if (timeoutInterval) clearInterval(timeoutInterval);
      navigate('/facebook-grader/error?event=process');
      return;
    default:
      if (timeoutInterval) clearInterval(timeoutInterval);
  }
};

export const useFacebookGrade = (uuid: string | undefined): ReturnType => {
  const navigate = useNavigate();
  const STATUS_URL = `${process.env.REACT_APP_BACKEND_URL}/facebook/process`;
  const URL = `${process.env.REACT_APP_BACKEND_URL}/facebook/grade`;
  const {
    data: processorData,
    error: processorError,
    mutate,
  } = useSWR<IFacebookGradeProcessWithStatus>((uuid && `${STATUS_URL}/${uuid}`) || null, {
    onSuccess: (data) => HandleSuccessFn(data, mutate, navigate),
  });

  const { data: gradeData, error: gradeError } = useSWR<IFacebookGrade>(
    () => (processorData?.status === GradeProcessorStatus.COMPLETE && `${URL}/${processorData.gradeUuid}`) || null,
  );

  return {
    loading: !gradeData && !gradeError,
    grade: gradeData,
    error: processorError || gradeError,
  };
};
