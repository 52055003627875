import { useContext, useEffect, useRef, useState } from 'react';
import { LoadingContext } from 'src/components/providers/loading';
import useIsMountedRef from 'src/hooks/common/useIsMountedRef';

const MAX = 98;
const DEFAULT_STEP = 0.375;

type Props = {
  callback?: () => void;
  throttle?: boolean;
};

type ReturnType = {
  value: number;
};

const calculateStep = (currentValue: number) => {
  switch (true) {
    case currentValue < 15:
      return 0.5;
    case currentValue < 32.25:
      return 0.375;
    case currentValue < 85:
      return 0.125;
    case currentValue < 94.75:
      return 0.25;
    case currentValue < 100:
      return 0.375;
    default:
      return DEFAULT_STEP;
  }
};

export const useProgress = ({ callback, throttle }: Props): ReturnType => {
  let interval: number | undefined = undefined;
  const { isApiComplete, setProgressBarComplete, setProgressBarRunning } = useContext(LoadingContext);
  const [running, setRunning] = useState(true);
  const [value, setValue] = useState(0);
  const valueRef = useRef(0);
  const isMounted = useIsMountedRef();

  useEffect(() => {
    if (running) setProgressBarRunning(true);
  }, [running]);

  useEffect(() => {
    if (running) {
      interval = window.setInterval(() => {
        if (!isMounted.current) return;
        const step = throttle ? calculateStep(valueRef.current) : DEFAULT_STEP;
        setValue((prev) => {
          if (prev <= MAX) valueRef.current = prev + step;
          return valueRef.current;
        });
      }, 325);
    }
  }, [running]);

  useEffect(() => {
    if (value === MAX || isApiComplete) {
      setRunning(false);
      setProgressBarRunning(false);
      clearInterval(interval);
    }
  }, [value, isApiComplete]);

  useEffect(() => {
    if (isApiComplete) {
      if (callback) callback();
      const timeout_1 = setTimeout(() => setValue(100), 150);
      const timeout_2 = setTimeout(() => setProgressBarComplete(true), 250);
      return () => {
        clearTimeout(timeout_1);
        clearTimeout(timeout_2);
      };
    }
  }, [isApiComplete]);

  return { value };
};
