import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { KeywordsBackground } from '../Background';
import { Footer } from '../Footer';
import { RefineModal } from '../refineModal/RefineModal';
import { KeywordsForm } from 'src/components/keywords/form/Form';
import { KeywordsPageTitle } from 'src/components/keywords/page/PageTitle';
import { StyledPageContent } from 'src/components/common/styled/StyledPageElements';

export const KeywordsPage: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }): JSX.Element => {
  return (
    <FlexBoxColumn>
      <KeywordsBackground>
        <StyledPageContent>
          <RefineModal />
          <KeywordsPageTitle />
          <KeywordsForm textColor="common.white" modalFormat={false} />
        </StyledPageContent>
      </KeywordsBackground>
      <StyledPageContent sx={{ py: 5 }}>
        <FlexBoxColumn alignSelf={'stretch'} gap={4}>
          {children}
        </FlexBoxColumn>
      </StyledPageContent>
      <Footer />
    </FlexBoxColumn>
  );
};
