import { MenuItem, Typography, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GoogleShoppingScoreSection } from 'src/enums/google/ScoreSection';
import { HorizontalBarGraph } from '../../../common/grade/charts/HorizontalBarGraph';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { ShoppingChartLabels } from 'src/components/common/grade/charts/Labels';
import { useSectionManager } from './useSectionManager';
import { useTranslation } from 'react-i18next';
import { commonChartOptions } from 'src/components/common/grade/charts/commonOptions';
import { DatasetDuration } from 'src/utils/datasetHelper';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useChartDefaults } from 'src/components/common/grade/charts/useChartDefaults';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { VerticalStackedGraph } from 'src/components/common/grade/charts/VerticalStackedGraph';
import { ScatterChart } from 'src/components/common/grade/charts/ScatterChart';
import { OrionColors } from 'src/components/theme/orion/common/colors';

const wastedSpendLabels = ['You', 'Industry Benchmark'];
const accountStructureLabels = ['Products', 'Product Groups', 'Ad Groups', 'Campaigns'];
const campaignRankingsLabels = ['Your Campaigns', 'Industry Benchmark Campaigns'];

export const ChartRenderer: React.FC<{ section: GoogleShoppingScoreSection }> = ({ section }) => {
  const { t } = useTranslation([GoogleAdType.SHOPPING, 'common']);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  const { title, onDurationChange, wastedSpendData, accountStructureData, campaignRankingsData, currencyCode } =
    useSectionManager({ section });

  useChartDefaults();

  return (
    <FlexBoxColumn gap={3} sx={{ alignSelf: 'stretch' }}>
      <>
        {section === GoogleShoppingScoreSection.WASTED_SPEND && (
          <ResponsiveRow style={{ justifyContent: 'space-between' }} alignSelf={'stretch'} breakpoint={600}>
            <Typography
              fontWeight={'fontWeightMedium'}
              sx={{
                alignSelf: 'flex-start',
                pb: isMobile ? 2 : 0,
                fontSize: '1.25rem',
                lineHeight: LineHeight.LOOSE,
              }}
            >
              {title}
            </Typography>
            <StyledSelect
              data-testid="durationSelect"
              defaultValue={'duration30'}
              sx={{ width: isMobile ? '100%' : OrionTheme.spacing(37) }}
              fullWidth={isMobile}
              size="small"
              onChange={(event) => onDurationChange(event.target.value as DatasetDuration, section)}
            >
              <MenuItem value={'duration30'}>{t('common:duration30')}</MenuItem>
              <MenuItem value={'duration90'}>{t('common:duration90')}</MenuItem>
            </StyledSelect>
          </ResponsiveRow>
        )}
      </>
      {wastedSpendData?.dataset && (
        <>
          <FlexBoxColumn data-testid="wastedSpendChart" alignSelf={'stretch'} gap={isMobile ? 1 : 2}>
            <Typography variant="body1">{t('grade.wastedSpend.chart.message')}</Typography>
            <HorizontalBarGraph
              dataset={[...wastedSpendData.dataset]}
              options={commonChartOptions}
              labels={wastedSpendLabels}
              xAxisText={t('grade.wastedSpend.chart.xAxis')}
              isMobile={isMobile}
            />
            <ShoppingChartLabels responsive={true} />
          </FlexBoxColumn>
        </>
      )}
      {accountStructureData?.dataset && (
        <>
          <FlexBoxColumn data-testid="accountStructureChart" alignSelf={'stretch'} gap={2}>
            <VerticalStackedGraph
              dataset={{ ...accountStructureData.dataset }}
              options={commonChartOptions}
              labels={accountStructureLabels}
              isMobile={isMobile}
            />
            <ShoppingChartLabels responsive={false} />
          </FlexBoxColumn>
        </>
      )}
      {campaignRankingsData?.dataset && (
        <>
          <FlexBoxColumn data-testid="campaignRankingsChart" alignSelf={'stretch'} gap={2}>
            <ScatterChart
              dataset={{
                campaigns: campaignRankingsData.dataset.campaigns,
                benchmarks: campaignRankingsData.dataset.benchmarks,
              }}
              labels={campaignRankingsLabels}
              options={commonChartOptions}
              isMobile={isMobile}
              currencyCode={currencyCode}
            />
            <ShoppingChartLabels responsive={true} section={section} />
            {section === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS && (
              <Typography variant="caption" sx={{ mt: -1, pl: isMobile ? 8.5 : 0, color: OrionColors.gray.mid }}>
                Hover over the datapoints to learn more about each point.
              </Typography>
            )}
          </FlexBoxColumn>
        </>
      )}
    </FlexBoxColumn>
  );
};
