import { useMediaQuery } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGGContext } from 'src/components/providers/googleAds';
import { useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import { IOverallGrade } from 'src/types/overall/OverallGrades';
import { IAccount } from 'src/types/common/Account';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { ConditionalClearTimeout, DelayNavigationToTimedOutError, TimeIDRef } from 'src/utils/helpers';
import { ServiceType } from 'src/enums/ServiceType';
import useGATracking from 'src/hooks/google/useGATracking';

const dataLayer = {
  event: 'gaTriggerEvent',
  gaEventCategory: 'Grader',
  gaEventAction: 'Impression',
  gaEventLabel: 'GraderStepReport',
};

interface SetAccountParams {
  grade: IOverallGrade;
  setter: Dispatch<SetStateAction<IAccount>>;
}

const setAccount = (params: SetAccountParams): void => {
  const accountId = params.grade.accountId;
  let displayName = params.grade.accountId;
  if (params.grade.accountName) {
    displayName = params.grade.accountName + ' (' + params.grade.accountId + ')';
  }
  params.setter({ accountId, accountName: displayName });
};

interface SetZeroDollarParams {
  grade: IOverallGrade;
  setter: Dispatch<SetStateAction<boolean>>;
  b4Shopping: boolean;
}

const setZeroDollar = (params: SetZeroDollarParams): void => {
  if (params.b4Shopping) {
    params.setter(params.grade.displayScores?.spend === 0 && params.grade.searchScores.spend === 0);
  } else {
    params.setter(params.grade.spend === 0);
  }
};

type ReturnType = {
  isZeroDollarsSpent: boolean;
  isMobile: boolean;
};

export const useReport = (): ReturnType => {
  const context = useGGContext();
  const { UUID } = useParams();
  const navigate = useNavigate();
  const {
    grade,
    error,
    b4Shopping = false,
  } = {
    grade: useOverallGrade(UUID).overallGrade,
    error: useOverallGrade(UUID).overallError,
    b4Shopping: useOverallGrade(UUID).b4Shopping,
  };
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const [isZeroDollarsSpent, setIsZeroDollarsSpent] = useState<boolean>(false);
  const timeIDRef: TimeIDRef = {
    timeID: undefined,
  };
  const { pushCustomGAEvent } = useGATracking({
    pageViewDefaults: { title: 'GoogleAdsGrader Report' },
    triggerOnMount: true,
  });

  useEffect(() => {
    if (UUID === undefined) navigate('/');
  }, []);

  useEffect(() => {
    if (!grade && !error) {
      timeIDRef.timeID = DelayNavigationToTimedOutError(navigate, ServiceType.GOOGLE);
    } else if (grade && !error) {
      ConditionalClearTimeout(timeIDRef);
      // Currently we are pushing event only with Search Report
      pushCustomGAEvent({ ...dataLayer, gaEventLabel: 'GraderOverview' });
      setAccount({ grade, setter: context.setGoogleAdsAccount });
      setZeroDollar({ grade, setter: setIsZeroDollarsSpent, b4Shopping });
    } else if (error) {
      ConditionalClearTimeout(timeIDRef);
      navigate('/google-grader/error?event=noRoute');
    }

    return () => ConditionalClearTimeout(timeIDRef);
  }, [grade, error, b4Shopping]);

  return { isZeroDollarsSpent, isMobile };
};
