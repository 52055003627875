import { useTranslation } from 'react-i18next';
import { StyledCardWithPadding } from '../../common/styled/StyledCard';
import { FormInputText } from '../../common/forms/inputs/FormInputText';
import { FormProvider, useForm } from 'react-hook-form';
import FormInputSelect from '../../common/forms/inputs/FormInputSelect';
import { FormHeader } from '../../common/forms/FormHeader';
import { FlexBoxColumn } from '../../common/FlexBoxColumn';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormStepsNextButton } from 'src/components/common/styled/FormStepsNextButton';
import { memo } from 'react';
import {
  FormStepOneStyledForm,
  getFormStepOneValidatitonSchema,
  BusinessTypesOptions,
} from 'src/components/common/styled/FormStepOne';
import { StyledRowStack } from 'src/components/common/styled/StyledRowStack';
import { useSubmit } from './useSubmit';
import { BusinessType } from 'src/enums/BusinessType';
import { useFAContext } from 'src/components/providers/facebookAds';
import { StyledFormLabel } from 'src/components/common/forms/FormLabel';
import { IFacebookContext } from 'src/types/facebook/FacebookContext';

const BusinessTypeDefaultValue = (context: IFacebookContext): BusinessType | string => {
  if (context.userDetails.isClient) {
    return BusinessType.CLIENT;
  } else if (context.userDetails.isClient !== undefined) {
    return BusinessType.SELF;
  }
  return '';
};

export const FormStepOne = memo(function FormStepOne(): JSX.Element {
  const { t } = useTranslation(['common']);
  const context = useFAContext();
  const methods = useForm({
    defaultValues: {
      firstName: context.userDetails.firstName ?? '',
      lastName: context.userDetails.lastName ?? '',
      phoneNumber: context.userDetails.phoneNumber ?? '',
      businessType: BusinessTypeDefaultValue(context),
    },
    mode: 'onChange',
    resolver: yupResolver(getFormStepOneValidatitonSchema({ t })),
  });

  const { onSubmit } = useSubmit(methods.getValues);

  return (
    <FormProvider {...methods}>
      <FormStepOneStyledForm>
        <FlexBoxColumn>
          <StyledCardWithPadding sx={{ gap: 3 }}>
            <FormHeader step={context.currentStep} />
            <FlexBoxColumn sx={{ width: '100%', gap: 2.5 }}>
              <FlexBoxColumn sx={{ width: '100%', gap: 1 }}>
                <StyledFormLabel error={!!methods.formState.errors.firstName}>
                  {t('formSteps.step1.firstName')}
                </StyledFormLabel>
                <FormInputText data-testid="firstName" name={'firstName'} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{ width: '100%', gap: 1 }}>
                <StyledFormLabel error={!!methods.formState.errors.lastName}>
                  {t('formSteps.step1.lastName')}
                </StyledFormLabel>
                <FormInputText data-testid="lastName" name={'lastName'} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{ width: '100%', gap: 1 }}>
                <StyledFormLabel error={!!methods.formState.errors.phoneNumber}>
                  {t('formSteps.step1.phoneNumber')}
                </StyledFormLabel>
                <FormInputText data-testid="phoneNumber" name={'phoneNumber'} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{ width: '100%', gap: 1 }}>
                <StyledFormLabel>{t('formSteps.step1.businessType')}</StyledFormLabel>
                <FormInputSelect testid="businessType" name="businessType" options={[...BusinessTypesOptions]} />
              </FlexBoxColumn>
            </FlexBoxColumn>
            <StyledRowStack sx={{ justifyContent: 'center' }}>
              <FormStepsNextButton onSubmit={onSubmit} id="fb-personal-info-next" />
            </StyledRowStack>
          </StyledCardWithPadding>
        </FlexBoxColumn>
      </FormStepOneStyledForm>
    </FormProvider>
  );
});
