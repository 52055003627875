import React from 'react';
import { OrionTheme } from '../../theme/orion/Theme';
import { ISpendDataKey } from './SpendBreakdown';
import { ISpend } from '../../../types/overall/OverallGrades';
import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../enums/ServiceType';
import { Table, TableBody, TableCell, TableRow, useMediaQuery } from '@mui/material';
import { ResponsiveRow } from '../../common/ResponsiveRow';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FlexBoxColumn } from '../../common/FlexBoxColumn';

type ISpendProps = {
  item: ISpendDataKey;
  keys: ISpendDataKey[];
  spendData: ISpend;
  currencySymbol: string;
  isMobile: boolean;
  singleColumn?: boolean;
};

export const SpendCell = ({
  item,
  keys,
  spendData,
  currencySymbol,
  isMobile,
  singleColumn,
}: ISpendProps): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  return (
    <Table key={item.key} sx={{ width: '100%' }}>
      <TableBody>
        <TableRow
          sx={{
            borderBottom: isMobile ? undefined : keys.length > 1 ? `1px solid ${OrionColors.gray.neutral}` : undefined,
            display: singleColumn && !isMobile ? 'flex' : undefined,
            justifyContent: singleColumn && !isMobile ? 'space-between' : undefined,
            width: singleColumn && !isMobile ? '100%' : undefined,
          }}
        >
          <TableCell
            size={isMobile ? 'small' : 'medium'}
            sx={{
              fontWeight: isMobile ? FontWeight.SemiBold : FontWeight.Regular,
              flex: singleColumn && !isMobile ? '1' : undefined,
              width: singleColumn && !isMobile ? '100%' : undefined,
            }}
          >
            {t(`metrics.overallSpend.${item.key}`)}
          </TableCell>
          <TableCell
            align="right"
            sx={{
              fontWeight: FontWeight.SemiBold,
              flex: singleColumn && !isMobile ? '1' : undefined,
              textAlign: singleColumn && !isMobile ? 'right' : undefined,
              width: singleColumn && !isMobile ? '100%' : undefined,
            }}
            size={isMobile ? 'small' : 'medium'}
          >
            {currencySymbol}
            {spendData[item.key].toLocaleString(process.env.LOCALE, { minimumFractionDigits: 2 })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const EmptySpendCell = (): JSX.Element => {
  return (
    <Table>
      <tbody>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </tbody>
    </Table>
  );
};

interface IProps {
  keys: ISpendDataKey[];
  spendData: ISpend;
  currencySymbol: string;
  singleColumn?: boolean;
}

export const OtherSpendRow = ({ keys, spendData, currencySymbol, singleColumn }: IProps): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  if (singleColumn && !isMobile) {
    return (
      <FlexBoxColumn sx={{ width: '100%' }}>
        {keys.map((item: ISpendDataKey, index: number) => (
          <SpendCell
            key={`single-column-spend-cell-${item.key}-${index}`}
            item={item}
            keys={keys}
            spendData={spendData}
            currencySymbol={currencySymbol}
            isMobile={isMobile}
            singleColumn={true}
          />
        ))}
      </FlexBoxColumn>
    );
  }

  return (
    <ResponsiveRow
      sx={{ alignSelf: 'stretch', alignItems: 'stretch' }}
      gap={isMobile ? 1 : 6}
      breakpoint={600}
      key={`responsive-row-${keys.map((key) => key.key).join('-')}`}
    >
      {keys.map((item: ISpendDataKey, index: number) => (
        <React.Fragment key={`fragment-${index}`}>
          {keys.length === 1 && !isMobile && <EmptySpendCell key={`key_1-${index}`} />}
          <SpendCell
            key={`spend-cell-${item.key}-${index}`}
            item={item}
            keys={keys}
            spendData={spendData}
            currencySymbol={currencySymbol}
            isMobile={isMobile}
          />
          {keys.length < 3 && !isMobile && <EmptySpendCell key={`key_2-${index}`} />}
        </React.Fragment>
      ))}
    </ResponsiveRow>
  );
};
