import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGGContext } from 'src/components/providers/googleAds';
import { IAccount } from 'src/types/common/Account';
import { ConditionalClearTimeout, DelayNavigationToTimedOutError, TimeIDRef } from 'src/utils/helpers';
import { ServiceType } from 'src/enums/ServiceType';
import { useShoppingGrade } from 'src/hooks/shopping/useShoppingGrade';
import { IShoppingGrade } from 'src/types/shopping/ShoppingGrade';
import useGATracking from 'src/hooks/google/useGATracking';

const dataLayer = {
  event: 'gaTriggerEvent',
  gaEventCategory: 'Grader',
  gaEventAction: 'Impression',
  gaEventLabel: 'GraderStepReport',
};

interface SetAccountParams {
  grade: IShoppingGrade;
  setter: Dispatch<SetStateAction<IAccount>>;
}

const setAccount = (params: SetAccountParams): void => {
  let displayName;
  const accountId = params.grade.overallScore.accountId;
  displayName = params.grade.overallScore.accountId;
  if (params.grade.overallScore.accountName) {
    displayName = params.grade.overallScore.accountName + ' (' + params.grade.overallScore.accountId + ')';
  }
  params.setter({ accountId, accountName: displayName });
};

interface SetZeroDollarParams {
  grade: IShoppingGrade;
  setter: Dispatch<SetStateAction<boolean>>;
}

const setZeroDollar = (params: SetZeroDollarParams): void => {
  params.setter(params.grade.shoppingPerformanceMetrics.spend === 0);
};

type ReturnType = {
  isZeroDollarsSpent: boolean;
};

export const useReport = (): ReturnType => {
  const context = useGGContext();
  const { UUID } = useParams();
  const navigate = useNavigate();
  const { grade, error } = { grade: useShoppingGrade(UUID).shoppingGrade, error: useShoppingGrade(UUID).shoppingError };
  const [isZeroDollarsSpent, setIsZeroDollarsSpent] = useState<boolean>(false);
  const timeIDRef: TimeIDRef = {
    timeID: undefined,
  };
  const { pushCustomGAEvent } = useGATracking({
    pageViewDefaults: { title: 'GoogleAdsGrader Report' },
    triggerOnMount: true,
  });

  useEffect(() => {
    if (UUID === undefined) navigate('/');
  }, []);

  useEffect(() => {
    if (!grade && !error) {
      timeIDRef.timeID = DelayNavigationToTimedOutError(navigate, ServiceType.GOOGLE);
    } else if (grade && !error) {
      ConditionalClearTimeout(timeIDRef);
      // Currently we are pushing event only with Search Report
      pushCustomGAEvent({ ...dataLayer, gaEventLabel: 'GraderShopping' });
      setAccount({ grade, setter: context.setGoogleAdsAccount });
      setZeroDollar({ grade, setter: setIsZeroDollarsSpent });
    } else if (error) {
      ConditionalClearTimeout(timeIDRef);
      navigate('/google-grader/error?event=noRoute');
    }

    return () => ConditionalClearTimeout(timeIDRef);
  }, [grade, error]);

  return { isZeroDollarsSpent };
};
