import { GoogleAdType } from 'src/enums/GoogleAdType';
import { IOverallGradeResponse, useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import { useParams } from 'react-router-dom';
import { GradeUtils } from 'src/utils/GradeUtils';
import { Button, Card, useMediaQuery } from '@mui/material';
import { BlockHeader } from './BlockHeader';
import { BoldTypography } from '../../common/styled/BoldTypography';
import { Trans, useTranslation } from 'react-i18next';
import { OrionTheme } from '../../theme/orion/Theme';
import { KeyFindings } from './KeyFindings';

type Props = {
  adType: GoogleAdType;
};

const ExtractValues = (
  googleAdType: GoogleAdType,
  overallGrade: IOverallGradeResponse,
): { scoreValue: number; spend: number } => {
  switch (googleAdType) {
    case GoogleAdType.SHOPPING:
      return {
        scoreValue: overallGrade.overallGrade?.shoppingScores?.overallScore ?? 0,
        spend: overallGrade.overallGrade?.shoppingScores?.spend ?? 0,
      };
    case GoogleAdType.DISPLAY:
      return {
        scoreValue: overallGrade.overallGrade?.displayScores?.overallScore ?? 0,
        spend: overallGrade.overallGrade?.displayScores?.spend ?? 0,
      };
    case GoogleAdType.OVERALL:
    default:
      return {
        scoreValue: overallGrade.overallGrade?.searchScores.overallScore ?? 0,
        spend: overallGrade.overallGrade?.searchScores.spend ?? 0,
      };
  }
};

export const ResultBlock = ({ adType }: Props): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { UUID } = useParams();
  const overallGrade = useOverallGrade(UUID);
  const notLargeScreen = useMediaQuery(OrionTheme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const url = `/google-grader/${adType}/${UUID}`;
  const { scoreValue, spend } = ExtractValues(adType, overallGrade);
  const keyAreaFindingsSections = adType === GoogleAdType.SEARCH ? 4 : 3;

  return (
    <Card
      sx={{
        padding: notLargeScreen ? 2 : 3,
        width: notLargeScreen ? (isMobile ? '100%' : OrionTheme.spacing(40)) : OrionTheme.spacing(48),
        cursor: spend ? 'default' : 'not-allowed',
      }}
    >
      <BlockHeader
        adType={adType}
        scoreName={GradeUtils.getScoreNameFromNumber(scoreValue)}
        scoreValue={spend > 0 ? scoreValue : '-'}
      />
      <BoldTypography variant="h5" sx={{ pt: notLargeScreen ? 2 : 3, pb: 2 }}>
        {spend === 0 ? (
          <>&nbsp;</>
        ) : (
          <Trans t={t} i18nKey={'keyAreaFindings'} values={{ quantity: keyAreaFindingsSections.toString() }} />
        )}
      </BoldTypography>
      <KeyFindings adType={adType} />
      <Button variant="outlined" color="black" onClick={() => window.location.replace(url)} disabled={spend === 0}>
        {t('reportLink_details')}
      </Button>
    </Card>
  );
};
