import { MenuProps, SelectChangeEvent, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelectOptions } from './useSelectOptions';
import { ISelectOption } from 'src/types/common/SelectOption';

import StyledSelect from '../../styled/StyledSelect';

export type Props = {
  name: string;
  options: ISelectOption[] | [];
  required?: boolean;
  testid?: string;
  changeHandler?: (event: SelectChangeEvent<string>) => void;
  size?: 'small' | 'medium';
  sx?: SxProps;
  disabled?: boolean;
  menuProps?: Partial<MenuProps>;
  isOptionsSorted?: boolean;
  isDefaultOptionRequired?: boolean;
};

const FormInputSelect = ({
  name,
  required,
  options,
  changeHandler,
  testid,
  size,
  sx,
  disabled,
  menuProps,
  isOptionsSorted,
  isDefaultOptionRequired,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const selectRef = useRef<Element>();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(460));
  const { selectOptions } = useSelectOptions(options, isOptionsSorted, isDefaultOptionRequired ?? true);

  useEffect(() => {
    if (!selectRef.current) return;
    setAnchorEl(selectRef.current);
  }, [selectRef]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => (
        <StyledSelect
          ref={selectRef}
          data-testid={testid}
          aria-label={name}
          disabled={disabled ?? false}
          MenuProps={{
            ...{ ...(menuProps ?? {}) },
            sx: {
              maxHeight: '400px',
              width: selectRef.current?.getBoundingClientRect()?.width ?? '100%',
              ...menuProps?.sx,
            },
            transitionDuration: isMobile ? 0 : 'auto',
            anchorEl,
          }}
          displayEmpty={true}
          onChange={(event) => {
            onChange(event);
            changeHandler?.(event as SelectChangeEvent<string>);
          }}
          value={value}
          error={!!errors[name]}
          size={size ?? 'medium'}
          sx={sx ?? {}}
        >
          {selectOptions}
        </StyledSelect>
      )}
    />
  );
};

export default FormInputSelect;
