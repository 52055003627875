import { TableRow as TableRowBasic, TableCell } from '@mui/material';
import { ScoreName } from 'src/enums/ScoreName';
import { GradeUtils } from 'src/utils/GradeUtils';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { OrionColors } from 'src/components/theme/orion/common/colors';

type Props = {
  text: string;
  you: number | string | undefined;
  competitors: number | string | undefined;
  scoreName: ScoreName;
  withIcon?: boolean;
  withBackground?: boolean;
};

export const TableRow = ({ text, you, competitors, scoreName, withIcon, withBackground }: Props): JSX.Element => {
  const iconColored = GradeUtils.getIconColored(scoreName as ScoreName);
  const bgColor = GradeUtils.getScoreColorLight(scoreName as ScoreName);

  return (
    <TableRowBasic
      sx={{
        borderBottom: `1px solid ${OrionColors.gray.neutral}`,
        background: withBackground ? OrionColors.gray.light : '',
      }}
    >
      <TableCell>
        {withIcon && <img style={{ width: '20px', height: '17px' }} src={iconColored} alt="Score-Icon" />}
        {text}
      </TableCell>
      <TableCell sx={{ backgroundColor: bgColor, fontWeight: FontWeight.SemiBold }} align="right">
        {you ?? '-'}
      </TableCell>
      <TableCell align="right">{competitors ?? '-'}</TableCell>
    </TableRowBasic>
  );
};
