import { Divider } from '@mui/material';
import { FlexBoxRow } from '../FlexBoxRow';

import checked from 'src/assets/adgenerator/stepper/checked.svg';
import step2 from 'src/assets/adgenerator/stepper/step-2.svg';
import step3 from 'src/assets/adgenerator/stepper/step-3.svg';
import step1current from 'src/assets/adgenerator/stepper/highlighted/step-1.svg';
import step2current from 'src/assets/adgenerator/stepper/highlighted/step-2.svg';
import step3current from 'src/assets/adgenerator/stepper/highlighted/step-3.svg';

export enum AdGeneratorStep {
  'INDUSTRY_IN',
  'CONTENT_TO_BE_INCLUDED',
  'CONTACT_DETAILS',
  'LOADING',
  'RESULT',
  'FINETUNE_RESULT',
}

export const Stepper = ({ currentStep }: { currentStep?: AdGeneratorStep }): JSX.Element => {
  currentStep = currentStep ?? AdGeneratorStep.INDUSTRY_IN;

  const Step1 =
    currentStep === AdGeneratorStep.INDUSTRY_IN ? (
      <img src={step1current} id="step1" alt="step-1-current" height={24} width={24} />
    ) : (
      <img src={checked} id="step1" alt="step-1-done" height={24} width={24} />
    );

  const Step2 =
    currentStep === AdGeneratorStep.CONTENT_TO_BE_INCLUDED ? (
      <img src={step2current} id="step2" alt="step-2-current" height={24} width={24} />
    ) : currentStep > AdGeneratorStep.CONTENT_TO_BE_INCLUDED ? (
      <img src={checked} id="logo" alt="LOCALiQ" height={24} width={24} />
    ) : (
      <img src={step2} id="logo" alt="LOCALiQ" height={24} width={24} />
    );

  const Step3 =
    currentStep === AdGeneratorStep.CONTACT_DETAILS ? (
      <img src={step3current} id="logo" alt="LOCALiQ" height={24} width={24} />
    ) : (
      <img src={step3} id="logo" alt="LOCALiQ" height={24} width={24} />
    );

  return (
    <FlexBoxRow sx={{ gap: 1, alignSelf: 'stretch' }}>
      {Step1}
      <Divider sx={{ borderColor: 'primary.light', borderWidth: 1, width: '7%' }} />
      {Step2}
      <Divider sx={{ borderColor: 'primary.light', borderWidth: 1, width: '7%' }} />
      {Step3}
    </FlexBoxRow>
  );
};
