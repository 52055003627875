import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TypedGrid } from 'src/components/common/Grid';
import { StyledPageContent } from 'src/components/common/styled/StyledPageElements';
import { GridType } from 'src/enums/GridType';
import { useLocation, useParams } from 'react-router-dom';
import { Header } from 'src/components/googleAds/grade/header/Header';
import { useContext, useEffect } from 'react';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { HeaderActions } from 'src/components/common/grade/header/headerActions';
import { ServiceType } from 'src/enums/ServiceType';
import { Progress } from 'src/components/common/progress/Progress';
import { LoadingContext } from 'src/components/providers/loading';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { useDisplayGrade } from 'src/hooks/display/useDisplayGrade';
import {
  FlexBoxRowWithBackground,
  FlexBoxRowWithBackgroundContainerGap,
  StyledPageContentGap,
  StyledPageContentHeight,
  StyledPageContentPB,
  StyledPageContentPT,
} from 'src/components/googleAds/page/PageContent';

type PageContentProps = {
  children: JSX.Element | JSX.Element[];
};

export const PageContentDisplay = ({ children }: PageContentProps): JSX.Element => {
  const { t } = useTranslation(['google', 'common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { UUID } = useParams();
  const { isProgressBarComplete, isProgressBarRunning, setApiComplete, setProgressBarComplete } =
    useContext(LoadingContext);
  const { grade, error } = { grade: useDisplayGrade(UUID).displayGrade, error: useDisplayGrade(UUID).displayError };
  const location = useLocation();
  const errord = !!error || location.pathname.includes('/error');
  const isLoading = error === undefined && grade === undefined;

  // Reset loading context states
  useEffect(() => {
    setProgressBarComplete(false);
    setApiComplete(false);
  }, []);

  // set api-complete as grade is loaded now ( or error'd )
  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setApiComplete(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  return (
    <FlexBoxColumn gap={FlexBoxRowWithBackgroundContainerGap(GridType.TAB, isMobile)}>
      <FlexBoxRowWithBackground
        gridType={GridType.TAB}
        errord={errord}
        isMobile={isMobile}
        isLoading={isProgressBarRunning}
      >
        <StyledPageContent
          sx={{
            height: StyledPageContentHeight(GridType.TAB, isProgressBarComplete, isMobile),
            alignItems: 'center',
            alignSelf: 'flex-start',
            paddingTop: StyledPageContentPT(GridType.TAB, isProgressBarComplete, isMobile, theme),
            paddingBottom: StyledPageContentPB(GridType.TAB, isMobile, theme),
            gap: StyledPageContentGap(GridType.TAB, isProgressBarComplete, isMobile, theme),
          }}
        >
          <ResponsiveRow
            breakpoint={'sm'}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              py: isMobile ? 0 : 3,
              gap: 3,
            }}
          >
            <FlexBoxColumn sx={{ alignItems: 'flex-start', flex: '1 1 0' }} gap={1}>
              <Typography variant="h3" color="common.white">
                {t('title1')}
              </Typography>
              <Typography variant="h4" color="common.white" fontWeight={'600'}>
                {t('title2')}
              </Typography>
            </FlexBoxColumn>
            {isProgressBarComplete && (
              <Box sx={{ alignSelf: isMobile ? 'stretch' : 'center' }}>
                <HeaderActions googleAdType={GoogleAdType.DISPLAY} />
              </Box>
            )}
          </ResponsiveRow>
          <FlexBoxColumn sx={{ alignSelf: 'stretch', flex: '1 0 0', pb: isProgressBarComplete ? 0 : 5 }}>
            {isProgressBarComplete ? (
              <FadeInTransition>
                <Header />
              </FadeInTransition>
            ) : (
              <FadeInTransition>
                <Progress serviceType={ServiceType.GOOGLE} />
              </FadeInTransition>
            )}
          </FlexBoxColumn>
        </StyledPageContent>
      </FlexBoxRowWithBackground>
      {isProgressBarComplete && (
        <StyledPageContent data-testid="pageContent" sx={{ justifyContent: 'flex-start' }}>
          <TypedGrid type={GridType.TAB}>{children}</TypedGrid>
        </StyledPageContent>
      )}
    </FlexBoxColumn>
  );
};
