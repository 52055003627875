import { Typography, useMediaQuery } from '@mui/material';
import { ScoreName } from 'src/enums/ScoreName';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { GradeSize, ScoreBox } from 'src/components/common/ScoreBox';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { OrionTheme } from 'src/components/theme/orion/Theme';

interface Props {
  adType: GoogleAdType | null;
  scoreName?: ScoreName;
  scoreValue?: string | number;
}

export const BlockHeader = ({ adType, scoreName, scoreValue }: Props): JSX.Element => {
  return (
    <FlexBoxRow
      sx={{
        gap: useMediaQuery(OrionTheme.breakpoints.down('lg')) ? 2 : 3,
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
      }}
    >
      <ScoreBox
        scoreName={scoreName}
        scoreValue={scoreValue}
        size={useMediaQuery(OrionTheme.breakpoints.down('lg')) ? GradeSize.Small : GradeSize.Medium}
      />
      <Typography variant="h4" sx={{ textTransform: 'capitalize', fontWeight: FontWeight.SemiBold }}>
        {`${adType} Results`}
      </Typography>
    </FlexBoxRow>
  );
};
