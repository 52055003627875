import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import {
  CustomerJourney,
  CustomerJourneyMobile,
} from 'src/components/facebook/grade/table/campaginGoals/CustomerJourney';
import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import { Benchmark } from '../table/campaginGoals/Benchmark';
import { CampaignSectionHeader } from '../table/campaginGoals/CampaignSectionHeader';
import { Objectives, ObjectivesMobile } from '../table/campaginGoals/Objectives';
import { Summary } from '../table/campaginGoals/Summary';
import { useCampaignScoreNames } from './useCampaignScoreNames';
import { useTypePercents } from './useTypePercents';
import { ServiceType } from 'src/enums/ServiceType';

export const CampaignGoalsResult = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: FacebookAdsScoreSection.CAMPAIGN_GOALS });
  const { awareness, consideration, conversion } = grade?.campaignGoals ?? {};
  const { percentAwareness, percentConsiderations, percentConversions } = useTypePercents({
    campaignGoals: grade?.campaignGoals,
  });
  const { awarenessScoreName, considerationScoreName, conversionScoreName } = useCampaignScoreNames({
    campaignGoals: grade?.campaignGoals,
  });

  return (
    <FlexBoxColumn gap={5} sx={{ alignItems: 'flex-start', width: '100%' }}>
      <CustomerJourney />
      <CampaignSectionHeader
        section="awareness"
        result={awareness?.impressions ?? 0}
        scoreName={awarenessScoreName}
        percent={percentAwareness}
      />
      <Benchmark
        scoreName={awarenessScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.engagementRate')}
        you={awareness?.engagementsRate}
        competitor={awareness?.competitorEngagementsRate}
      />
      <Summary
        campaigns={awareness?.campaignCount}
        clicks={awareness?.clicks}
        costPerResult={awareness?.costPerResult}
        ctr={awareness?.ctr}
        totalResults={awareness?.totalResults}
        totalSpend={awareness?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <Objectives objectives={awareness?.statsByObjective} currencySymbol={currencySymbol} />
      <CampaignSectionHeader
        section="consideration"
        result={consideration?.clicks ?? 0}
        scoreName={considerationScoreName}
        percent={percentConsiderations}
      />
      <Benchmark
        scoreName={considerationScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.ctr')}
        you={consideration?.ctr}
        competitor={consideration?.competitorCtr}
      />
      <Summary
        campaigns={consideration?.campaignCount}
        clicks={consideration?.clicks}
        costPerResult={consideration?.costPerResult}
        ctr={consideration?.ctr}
        totalResults={consideration?.totalResults}
        totalSpend={consideration?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <Objectives objectives={consideration?.statsByObjective} currencySymbol={currencySymbol} />
      <CampaignSectionHeader
        section="conversion"
        result={conversion?.totalResults ?? 0}
        scoreName={conversionScoreName}
        percent={percentConversions}
      />
      <Benchmark
        scoreName={conversionScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.ctr')}
        you={conversion?.ctr}
        competitor={conversion?.competitorCtr}
      />
      <Summary
        campaigns={conversion?.campaignCount}
        clicks={conversion?.clicks}
        costPerResult={conversion?.costPerResult}
        ctr={conversion?.ctr}
        totalResults={conversion?.totalResults}
        totalSpend={conversion?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <Objectives objectives={conversion?.statsByObjective} currencySymbol={currencySymbol} />
    </FlexBoxColumn>
  );
};

export const CampaignGoalsResultMobile = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: FacebookAdsScoreSection.CAMPAIGN_GOALS });
  const { awareness, consideration, conversion } = grade?.campaignGoals ?? {};
  const { percentAwareness, percentConsiderations, percentConversions } = useTypePercents({
    campaignGoals: grade?.campaignGoals,
  });
  const { awarenessScoreName, considerationScoreName, conversionScoreName } = useCampaignScoreNames({
    campaignGoals: grade?.campaignGoals,
  });

  return (
    <FlexBoxColumn gap={4} sx={{ width: '100%' }}>
      <CustomerJourneyMobile />
      <CampaignSectionHeader
        section="awareness"
        result={grade?.campaignGoals.awareness.impressions ?? 0}
        scoreName={awarenessScoreName}
        percent={percentAwareness}
      />
      <Benchmark
        scoreName={awarenessScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.engagementRate')}
        you={awareness?.engagementsRate}
        competitor={awareness?.competitorEngagementsRate}
      />
      <Summary
        campaigns={awareness?.campaignCount}
        clicks={awareness?.clicks}
        costPerResult={awareness?.costPerResult}
        ctr={awareness?.ctr}
        totalResults={awareness?.totalResults}
        totalSpend={awareness?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <ObjectivesMobile objectives={awareness?.statsByObjective} currencySymbol={currencySymbol} />
      <CampaignSectionHeader
        section="consideration"
        result={grade?.campaignGoals.consideration.clicks ?? 0}
        scoreName={considerationScoreName}
        percent={percentConsiderations}
      />
      <Benchmark
        scoreName={considerationScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.ctr')}
        you={consideration?.ctr}
        competitor={consideration?.competitorCtr}
      />
      <Summary
        campaigns={consideration?.campaignCount}
        clicks={consideration?.clicks}
        costPerResult={consideration?.costPerResult}
        ctr={consideration?.ctr}
        totalResults={consideration?.totalResults}
        totalSpend={consideration?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <ObjectivesMobile objectives={consideration?.statsByObjective} currencySymbol={currencySymbol} />
      <CampaignSectionHeader
        section="conversion"
        result={grade?.campaignGoals.conversion.totalResults ?? 0}
        scoreName={conversionScoreName}
        percent={percentConversions}
      />
      <Benchmark
        scoreName={conversionScoreName}
        rowTitle={t('grade.campaignGoals.table.benchmark.ctr')}
        you={conversion?.ctr}
        competitor={conversion?.competitorCtr}
      />
      <Summary
        campaigns={conversion?.campaignCount}
        clicks={conversion?.clicks}
        costPerResult={conversion?.costPerResult}
        ctr={conversion?.ctr}
        totalResults={conversion?.totalResults}
        totalSpend={conversion?.totalSpend}
        currencySymbol={currencySymbol}
      />
      <ObjectivesMobile objectives={conversion?.statsByObjective} currencySymbol={currencySymbol} />
    </FlexBoxColumn>
  );
};
