import { TableCell, TableRow } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { GradeUtils } from 'src/utils/GradeUtils';
import { OptimizationLevelType } from 'src/enums/shopping/CampaignRank';

type Props = {
  name: string;
  spend: number;
  impressionShare: number;
  ctr: number;
  optimizationLevel: OptimizationLevelType;
  currencySymbol: string;
  enumerate: boolean;
  index: number;
  applyBgColor: boolean;
};

export const CampaignRankRow = ({
  name,
  spend,
  impressionShare,
  ctr,
  optimizationLevel,
  currencySymbol,
  index,
  enumerate,
  applyBgColor,
}: Props): JSX.Element => {
  const scoreName = GradeUtils.mapOptimizationLevelToScoreName(optimizationLevel);
  const bgColor = scoreName ? GradeUtils.getScoreColorLight(scoreName) : '';

  return (
    <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
      <TableCell
        sx={{
          backgroundColor: applyBgColor ? bgColor : '',
          wordBreak: 'break-all',
          overflowWrap: 'break-word',
          hyphens: 'auto',
          whiteSpace: 'normal',
        }}
        colSpan={4}
        align="left"
      >
        {enumerate ? index + '. ' : ''}
        {name}
      </TableCell>
      <TableCell colSpan={2} align="right">
        {currencySymbol}
        {spend.toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 2 })}
      </TableCell>
      <TableCell colSpan={2} align="right">
        {impressionShare.toLocaleString(process.env.REACT_APP_LOCALE, { maximumFractionDigits: 0 })}%
      </TableCell>
      <TableCell colSpan={2} align="right">
        {ctr.toLocaleString(process.env.REACT_APP_LOCALE, { maximumFractionDigits: 2, style: 'percent' })}
      </TableCell>
    </TableRow>
  );
};
