import { NavBarContainerGoogleAds } from '../../common/NavBarContainer';
import { GridType } from 'src/enums/GridType';
import { PageContentSearch } from './PageContent';
import { Footer } from 'src/components/common/grade/footer/Footer';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { GoogleAdTypeContext } from '../../providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { LoadingContext } from 'src/components/providers/loading';
import { PageContentDisplay } from 'src/pages/displayAds/PageContent';
import { PageContentShopping } from 'src/pages/shopping/PageContent';
import { PageContentOverall } from 'src/pages/overall/PageContent';

type ContentWithBackGroundProps = {
  gridType: GridType.TAB | GridType.FORM;
  additionalFooter?: JSX.Element;
  isMobile: boolean;
};

export const FooterContent = ({ ...props }: ContentWithBackGroundProps): JSX.Element => {
  const { isProgressBarComplete } = useContext(LoadingContext);
  switch (props.gridType) {
    case 'TAB':
      if (isProgressBarComplete)
        return (
          <FadeInTransition triggerAfter={750}>
            <Footer sx={{ mt: props.isMobile ? 2 : 7, width: '100vw' }} />
          </FadeInTransition>
        );
      else
        return <FlexBoxColumn sx={{ width: '100vw', alignItems: 'stretch' }}>{props.additionalFooter}</FlexBoxColumn>;
    default:
      return <FlexBoxColumn sx={{ width: '100vw', alignItems: 'stretch' }}>{props.additionalFooter}</FlexBoxColumn>;
  }
};

type Props = {
  gridType: GridType.TAB | GridType.FORM;
  children: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
};

export const GoogleAdsPage = (props: Props): JSX.Element => {
  const { children, additionalFooter, gridType } = props;
  const { isSearch, isDisplay, isShopping, isOverall, setGoogleAdType } = useContext(GoogleAdTypeContext);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const { pathname } = useLocation();

  useEffect(() => {
    switch (true) {
      case pathname.includes(GoogleAdType.SEARCH):
        setGoogleAdType(GoogleAdType.SEARCH);
        break;
      case pathname.includes(GoogleAdType.DISPLAY):
        setGoogleAdType(GoogleAdType.DISPLAY);
        break;
      case pathname.includes(GoogleAdType.SHOPPING):
        setGoogleAdType(GoogleAdType.SHOPPING);
        break;
      default: // overall & error
        setGoogleAdType(GoogleAdType.OVERALL);
        break;
    }
  }, []);

  return (
    <NavBarContainerGoogleAds>
      <>
        {isSearch && <PageContentSearch>{children}</PageContentSearch>}
        {isDisplay && <PageContentDisplay>{children}</PageContentDisplay>}
        {isShopping && <PageContentShopping>{children}</PageContentShopping>}
        {isOverall && <PageContentOverall gridType={props.gridType}>{children}</PageContentOverall>}
        {/* Footer */}
        <FooterContent additionalFooter={additionalFooter} gridType={gridType} isMobile={isMobile} />
      </>
    </NavBarContainerGoogleAds>
  );
};
