import { Trans, useTranslation } from 'react-i18next';
import { useDisplayGradeScore } from 'src/hooks/display/useDisplayGradeScore';
import { Divider, Table, TableBody, Typography, useMediaQuery, TableCell } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { TableHeader } from './TableHeader';
import { AdPerformanceTableRow, BlackTableRow, TableRowMobile } from './TableRow';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { FlexBoxRow } from '../../../common/FlexBoxRow';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { AudienceSegments } from './AudienceSegments';
import { GoogleDisplayScoreSection } from 'src/enums/google/ScoreSection';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';

export const AudiencePerformance = ({ sectionName }: { sectionName: GoogleDisplayScoreSection }): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const { displayGrade, currencyCode } = useDisplayGradeScore({ sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <>
      <FlexBoxColumn sx={{ width: '100%', gap: 2 }}>
        <Typography variant="h4" fontWeight={'fontWeightBold'} sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}>
          {t('grade.audiencePerformance.breakdown')}
        </Typography>
        <Typography variant="body1">
          <Trans t={t} i18nKey={'grade.audiencePerformance.breakdownDescription'} />
        </Typography>
        <Table sx={{ marginTop: 1 }}>
          <TableHeader />
          <TableBody>
            {displayGrade && (
              <AdPerformanceTableRow
                title={t('grade.audiencePerformance.allAudienceTypes')}
                boldTitle={true}
                activeAudiences={displayGrade.displayAudienceMetrics.totalActiveAudiences}
                boldActiveAudiences={true}
                impressions={displayGrade.displayAudienceMetrics.totalImpressions}
                boldImpressions={true}
                conversions={displayGrade.displayAudienceMetrics.totalConversions}
                boldConversions={true}
                cpc={displayGrade.displayAudienceMetrics.totalCpc}
                boldCpc={true}
                currencyCode={currencyCode}
                withBackground={true}
              />
            )}
            {displayGrade && (
              <AdPerformanceTableRow
                title={t('grade.audiencePerformance.yourData')}
                boldTitle={true}
                activeAudiences={displayGrade.displayAudienceMetrics.totalRemarketingAudiences}
                impressions={displayGrade.displayAudienceMetrics.remarketingImpressions}
                conversions={displayGrade.displayAudienceMetrics.remarketingConversions}
                cpc={displayGrade.displayAudienceMetrics.remarketingCpc}
                currencyCode={currencyCode}
              />
            )}
            {displayGrade && (
              <AdPerformanceTableRow
                title={t('grade.audiencePerformance.audienceSegments')}
                boldTitle={true}
                activeAudiences={displayGrade.displayAudienceMetrics.totalNonRemarketingAudiences}
                impressions={displayGrade.displayAudienceMetrics.totalNonRemarketingImpressions}
                conversions={displayGrade.displayAudienceMetrics.totalNonRemarketingConversions}
                cpc={displayGrade.displayAudienceMetrics.totalNonRemarketingCpc}
                currencyCode={currencyCode}
              />
            )}
          </TableBody>
        </Table>
        <Typography variant="body1">
          <Trans t={t} i18nKey={'grade.audiencePerformance.targetingDescription'} />
        </Typography>
      </FlexBoxColumn>
      <AudienceSegments />
    </>
  );
};

export const AudiencePerformanceMobile = ({ sectionName }: { sectionName: GoogleDisplayScoreSection }): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const { displayGrade, currencyCode } = useDisplayGradeScore({ sectionName });

  return (
    <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%' }} gap={3}>
      <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%' }} gap={2}>
        <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%' }} gap={1}>
          <BoldTypography variant="h5">{t('grade.audiencePerformance.breakdown')}</BoldTypography>
          <Typography variant="h5">
            <Trans t={t} i18nKey={'grade.audiencePerformance.breakdownDescription'} />
          </Typography>
        </FlexBoxColumn>
        <FlexBoxRow sx={{ gap: 1, justifyContent: 'flex-start' }}>
          <FlagOutlinedIcon />
          <BoldTypography variant="h6">{t('grade.audiencePerformance.breakdown')}</BoldTypography>
        </FlexBoxRow>
        <FlexBoxColumn sx={{ width: '100%' }}>
          <table style={{ width: '100%' }}>
            <tbody>
              <BlackTableRow sx={{ width: '100%' }}>
                <TableCell color="white" size="small">
                  <FlexBoxColumn sx={{ alignItems: 'flex-start' }}>
                    <BoldTypography variant="h6">{t('grade.audiencePerformance.allAudienceTypes')}</BoldTypography>
                  </FlexBoxColumn>
                </TableCell>
              </BlackTableRow>
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.activeAudiences')}
                  value={displayGrade.displayAudienceMetrics.totalActiveAudiences.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.impressions')}
                  value={displayGrade.displayAudienceMetrics.totalImpressions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.conversions')}
                  value={displayGrade.displayAudienceMetrics.totalConversions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.cpc')}
                  value={displayGrade.displayAudienceMetrics.totalCpc.toLocaleString(process.env.REACT_APP_LOCALE, {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: currencyCode,
                  })}
                />
              )}
              <tr>
                <td>
                  <Divider sx={{ width: '100%', marginY: 1, borderColor: 'colors.gray.neutral' }} />
                </td>
              </tr>
              <tr>
                <TableCell size="small" width={'100%'}>
                  <FlexBoxColumn sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="h6" fontWeight={'fontWeightBold'}>
                      {t('grade.audiencePerformance.yourData')}
                    </Typography>
                  </FlexBoxColumn>
                </TableCell>
              </tr>
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.activeAudiences')}
                  value={displayGrade.displayAudienceMetrics.totalRemarketingAudiences.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.impressions')}
                  value={displayGrade.displayAudienceMetrics.remarketingImpressions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.conversions')}
                  value={displayGrade.displayAudienceMetrics.remarketingConversions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.cpc')}
                  value={displayGrade.displayAudienceMetrics.remarketingCpc.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                    { minimumFractionDigits: 2, style: 'currency', currency: currencyCode },
                  )}
                />
              )}
              <tr>
                <td>
                  <Divider sx={{ width: '100%', marginY: 1, borderColor: 'colors.gray.neutral' }} />
                </td>
              </tr>
              <tr>
                <TableCell size="small" width={'100%'}>
                  <FlexBoxColumn ml={1} sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="h6" fontWeight={'fontWeightBold'}>
                      {t('grade.audiencePerformance.audienceSegments')}
                    </Typography>
                  </FlexBoxColumn>
                </TableCell>
              </tr>
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.activeAudiences')}
                  value={displayGrade.displayAudienceMetrics.totalNonRemarketingAudiences.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.impressions')}
                  value={displayGrade.displayAudienceMetrics.totalNonRemarketingImpressions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.conversions')}
                  value={displayGrade.displayAudienceMetrics.totalNonRemarketingConversions.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                  )}
                />
              )}
              {displayGrade && (
                <TableRowMobile
                  text={t('grade.audiencePerformance.cpc')}
                  value={displayGrade.displayAudienceMetrics.totalNonRemarketingCpc.toLocaleString(
                    process.env.REACT_APP_LOCALE,
                    { minimumFractionDigits: 2, style: 'currency', currency: currencyCode },
                  )}
                />
              )}
              <tr>
                <td>
                  <Divider sx={{ width: '100%', marginY: 1, borderColor: 'colors.gray.neutral' }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="body1">
                    <Trans t={t} i18nKey={'grade.audiencePerformance.targetingDescription'} />
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </FlexBoxColumn>
      </FlexBoxColumn>
      <AudienceSegments />
    </FlexBoxColumn>
  );
};
