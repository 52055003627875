import { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';

type PageViewProps = {
  title: string;
  auth?: string;
};

type Props = {
  pageViewDefaults?: PageViewProps;
  triggerOnMount?: boolean;
};

type ReturnType = {
  pushPageViewEvent: (input: PageViewProps) => void;
  pushCustomGAEvent: (dataLayer: Record<string, string>) => void;
};

const useGATracking = ({ pageViewDefaults, triggerOnMount = false }: Props): ReturnType => {
  const pushPageViewEvent = useCallback((input?: PageViewProps) => {
    const page: Record<string, string> = {
      url: location.pathname + location.search,
      hostname: location.hostname,
      title: input?.title ?? (pageViewDefaults?.title as string),
    };

    if (input?.auth || pageViewDefaults?.auth) {
      page.auth = input?.auth ?? (pageViewDefaults?.auth as string);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'gaTriggerPageView',
        page,
      },
    });
  }, []);

  const pushCustomGAEvent = useCallback((dataLayer: Record<string, string>) => {
    console.log('PUSHING', dataLayer);
    TagManager.dataLayer({
      dataLayer,
    });
  }, []);

  useEffect(() => {
    if (triggerOnMount) {
      pushPageViewEvent();
    }
  }, []);

  return { pushPageViewEvent, pushCustomGAEvent };
};

export default useGATracking;
