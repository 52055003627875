import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TypedGrid } from 'src/components/common/Grid';
import { StyledPageContent } from 'src/components/common/styled/StyledPageElements';
import { GridType } from 'src/enums/GridType';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { HeaderActions } from 'src/components/common/grade/header/headerActions';
import { ServiceType } from 'src/enums/ServiceType';
import { Progress } from 'src/components/common/progress/Progress';
import { LoadingContext } from 'src/components/providers/loading';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import { OverallHeader } from 'src/components/overall/header/Header';
import {
  FlexBoxRowWithBackground,
  FlexBoxRowWithBackgroundContainerGap,
  StyledPageContentGap,
  StyledPageContentHeight,
  StyledPageContentPB,
  StyledPageContentPT,
} from 'src/components/googleAds/page/PageContent';

type PageContentProps = {
  gridType: GridType;
  children: JSX.Element | JSX.Element[];
};

export const PageContentOverall = ({ gridType, children }: PageContentProps): JSX.Element => {
  const { t } = useTranslation(['google', 'common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { UUID } = useParams();
  const { isProgressBarComplete, isProgressBarRunning, setApiComplete, setProgressBarComplete } =
    useContext(LoadingContext);
  const { grade, error } = { grade: useOverallGrade(UUID).overallGrade, error: useOverallGrade(UUID).overallError };
  const location = useLocation();
  const errord = !!error || location.pathname.includes('/error');
  const isLoading = error === undefined && grade === undefined;

  // Reset loading context states
  useEffect(() => {
    setProgressBarComplete(false);
    setApiComplete(false);
  }, []);

  // set api-complete as grade is loaded now ( or error'd )
  useEffect(() => {
    if (!isLoading || errord) {
      const timeout = setTimeout(() => setApiComplete(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoading, errord]);

  return (
    <FlexBoxColumn gap={FlexBoxRowWithBackgroundContainerGap(gridType, isMobile)}>
      <FlexBoxRowWithBackground
        gridType={gridType}
        errord={errord}
        isMobile={isMobile}
        isLoading={isProgressBarRunning}
      >
        <StyledPageContent
          sx={{
            height: StyledPageContentHeight(gridType, isProgressBarComplete, isMobile),
            alignItems: gridType === GridType.TAB ? 'center' : 'stretch',
            alignSelf: gridType === GridType.TAB ? 'flex-start' : 'stretch',
            paddingTop: StyledPageContentPT(gridType, isProgressBarComplete, isMobile, theme),
            paddingBottom: StyledPageContentPB(gridType, isMobile, theme),
            gap: StyledPageContentGap(gridType, isProgressBarComplete, isMobile, theme),
          }}
        >
          {gridType === GridType.TAB ? (
            <>
              <ResponsiveRow
                breakpoint={'sm'}
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  py: isMobile ? 0 : 3,
                  gap: 3,
                }}
              >
                <FlexBoxColumn sx={{ alignItems: 'flex-start', flex: '1 1 0' }} gap={1}>
                  <Typography variant="h3" color="common.white">
                    {t('title1')}
                  </Typography>
                  <Typography variant="h4" color="common.white" fontWeight={'600'}>
                    {t('title2')}
                  </Typography>
                </FlexBoxColumn>
                {isProgressBarComplete && (
                  <Box sx={{ alignSelf: isMobile ? 'stretch' : 'center' }}>
                    <HeaderActions googleAdType={GoogleAdType.OVERALL} />
                  </Box>
                )}
              </ResponsiveRow>
              <FlexBoxColumn sx={{ alignSelf: 'stretch', flex: '1 0 0', pb: isProgressBarComplete ? 0 : 5 }}>
                {isProgressBarComplete ? (
                  <FadeInTransition>
                    <OverallHeader />
                  </FadeInTransition>
                ) : (
                  <FadeInTransition>
                    <Progress serviceType={ServiceType.GOOGLE} />
                  </FadeInTransition>
                )}
              </FlexBoxColumn>
            </>
          ) : (
            <TypedGrid type={gridType}>
              <FlexBoxColumn gap={7} sx={{ alignItems: 'stretch' }}>
                <Typography variant="h1" color="common.white" sx={{ alignSelf: 'center' }}>
                  {t('title1')}&nbsp;Grader
                </Typography>
                {children}
              </FlexBoxColumn>
            </TypedGrid>
          )}
        </StyledPageContent>
      </FlexBoxRowWithBackground>
      {!isProgressBarComplete ? (
        <></>
      ) : (
        <StyledPageContent data-testid="pageContent" sx={{ justifyContent: 'flex-start' }}>
          {gridType === GridType.TAB && <TypedGrid type={gridType}>{children}</TypedGrid>}
        </StyledPageContent>
      )}
    </FlexBoxColumn>
  );
};
