import {
  Box,
  Card,
  colors,
  Divider,
  dividerClasses,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { GradeUtils } from 'src/utils/GradeUtils';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import {
  GoogleAdsScoreSection,
  GoogleDisplayScoreSection,
  GoogleScoreSectionType,
  GoogleShoppingScoreSection,
} from 'src/enums/google/ScoreSection';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useDisplayGradeScore } from 'src/hooks/display/useDisplayGradeScore';
import { useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';
import { useGGradeScore } from 'src/hooks/google/useGGradeScore';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';

import Wrench from 'src/assets/WrenchIcon.svg';
import styled from 'styled-components';

type Props = {
  sectionName: GoogleScoreSectionType;
};

export const HowToImproveInfoDivider = styled(Divider)`
  &.${dividerClasses.withChildren}:before, &.${dividerClasses.withChildren}:after {
    border-top: ${({ theme }) => theme.spacing(0.125)} solid ${({ theme }) => theme.palette.info.main};
  }
`;

type HowToImproveListSubHeaderProps = {
  title: string;
  isMobile: boolean;
};

export const HowToImproveListSubHeader = ({ title, isMobile }: HowToImproveListSubHeaderProps): JSX.Element => (
  <ListSubheader sx={{ p: 0, paddingBottom: isMobile ? 2 : 0 }}>
    <FlexBoxRow sx={{ alignItems: 'stretch', justifyContent: 'flex-start', gap: 2 }}>
      {isMobile ? <></> : <img src={Wrench} alt="WordStream Grader Report Recommendations" />}
      <Typography variant={'h5'} sx={{ color: colors.common.black, fontWeight: FontWeight.SemiBold }}>
        {title}
      </Typography>
    </FlexBoxRow>
  </ListSubheader>
);

type HowToImproveListItemsProps = {
  items: string[];
  isMobile: boolean;
};

export const HowToImproveListItems = ({ items, isMobile }: HowToImproveListItemsProps): JSX.Element[] => {
  return items.map((item) => (
    <ListItem key={item} disableGutters={true} sx={{ display: 'list-item', p: 0 }}>
      <ListItemText sx={{ paddingLeft: isMobile ? 0 : 6, color: colors.common.black }}>
        <Trans i18nKey={item} />
      </ListItemText>
    </ListItem>
  ));
};

export const HowToImproveDisplay = ({ sectionName }: Props): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.DISPLAY]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const i18Keys = GradeUtils.getDisplayHowToContent({
    grade: useDisplayGradeScore({ sectionName: undefined }).displayGrade,
    sectionName: sectionName as GoogleDisplayScoreSection,
  });

  return (
    <Card sx={{ borderRadius: 0, borderColor: OrionTheme.palette.info.main, p: isMobile ? 3 : 4, width: '100%' }}>
      {isMobile ? (
        <Box sx={{ pb: 2 }}>
          <HowToImproveInfoDivider>
            <img src={Wrench} alt="WordStream How To Improve Your ROI" />
          </HowToImproveInfoDivider>
        </Box>
      ) : (
        <></>
      )}
      <List
        sx={{
          listStyleType: 'none',
          paddingBottom: 0,
          border: 0,
          boxShadow: 0,
        }}
        subheader={<HowToImproveListSubHeader isMobile={isMobile} title={t('grade.howTo_improve')} />}
      >
        {HowToImproveListItems({
          items: i18Keys.map((key) => t(`grade.howTo.${key}`, { context: sectionKey })),
          isMobile: isMobile,
        })}
      </List>
    </Card>
  );
};

export const HowToImproveSearch = ({ sectionName }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const i18Keys = [useGGradeScore({ sectionName: sectionName as GoogleAdsScoreSection }).scoreName?.toLowerCase()];

  return (
    <Card sx={{ borderRadius: 0, borderColor: OrionTheme.palette.info.main, p: isMobile ? 3 : 4, width: '100%' }}>
      {isMobile ? (
        <Box sx={{ pb: 2 }}>
          <HowToImproveInfoDivider>
            <img src={Wrench} alt="WordStream How To Improve Your ROI" />
          </HowToImproveInfoDivider>
        </Box>
      ) : (
        <></>
      )}
      <List
        sx={{
          listStyleType: 'none',
          paddingBottom: 0,
          border: 0,
          boxShadow: 0,
        }}
        subheader={<HowToImproveListSubHeader isMobile={isMobile} title={t('grade.howTo_improve')} />}
      >
        {HowToImproveListItems({
          items: [t(`grade.${sectionKey}.howTo`, { context: i18Keys[0] })],
          isMobile: isMobile,
        })}
      </List>
    </Card>
  );
};

export const HowToImproveShopping = ({ sectionName }: Props): JSX.Element => {
  const { t } = useTranslation([GoogleAdType.SHOPPING]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const i18Keys = [
    useShoppingGradeScore({ sectionName: sectionName as GoogleShoppingScoreSection }).scoreName?.toLowerCase(),
  ];

  return (
    <Card sx={{ borderRadius: 0, borderColor: OrionTheme.palette.info.main, p: isMobile ? 3 : 4, width: '100%' }}>
      {isMobile ? (
        <Box sx={{ pb: 2 }}>
          <HowToImproveInfoDivider>
            <img src={Wrench} alt="WordStream How To Improve Your ROI" />
          </HowToImproveInfoDivider>
        </Box>
      ) : (
        <></>
      )}
      <List
        sx={{
          listStyleType: 'none',
          paddingBottom: 0,
          border: 0,
          boxShadow: 0,
        }}
        subheader={<HowToImproveListSubHeader isMobile={isMobile} title={t('grade.howTo_improve')} />}
      >
        {HowToImproveListItems({
          items: [t(`grade.${sectionKey}.howTo`, { context: i18Keys[0] })],
          isMobile: isMobile,
        })}
      </List>
    </Card>
  );
};
