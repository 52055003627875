import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { GridType } from 'src/enums/GridType';
import { Typography } from '@mui/material';
import { MetricsAccordions, MetricsTabs } from 'src/components/overall/metrics/Metrics';
import { TFunction, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { PageTitle } from 'src/enums/PageTitle';
import { useDriftChat } from 'src/hooks/useDriftChat';
import { ResultBlocks } from 'src/components/overall/resultBlocks/ResultBlocks';
import { useReport } from './useReport';
import { GoogleAdsNoSpend } from '../googleAds/NoSpend';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { ServiceType } from 'src/enums/ServiceType';
import GooglePartnerSVG from 'src/assets/GooglePartner.svg';
import BadgeFooter from 'src/components/common/BadgeFooter';

interface ContentProps {
  isMobile: boolean;
  t: TFunction;
}

const Content = ({ isMobile, t }: ContentProps): JSX.Element => (
  <FlexBoxColumn gap={7}>
    <FadeInTransition triggerAfter={750}>
      <ResultBlocks />
    </FadeInTransition>
    <FadeInTransition triggerAfter={750}>
      <Typography
        variant="h3"
        sx={{
          whiteSpace: isMobile ? 'pre' : 'normal',
          textAlign: 'center',
          paddingBottom: 3.75,
        }}
      >
        {t('overall.metricsSectionTitle')}
      </Typography>
      {isMobile ? <MetricsAccordions /> : <MetricsTabs />}
    </FadeInTransition>
  </FlexBoxColumn>
);

export const OverallReport = (): JSX.Element => {
  const { t } = useTranslation('common');
  const { isZeroDollarsSpent, isMobile } = useReport();
  const chatCode = useDriftChat();

  return (
    <>
      <Helmet>
        <title>
          {PageTitle.GOOGLE} | {'Report'}
        </title>
        <script>{chatCode}</script>
      </Helmet>
      {isZeroDollarsSpent && <GoogleAdsNoSpend />}
      {!isZeroDollarsSpent && (
        <GoogleAdsPage
          gridType={GridType.TAB}
          additionalFooter={
            <BadgeFooter
              i18nNameSpace={ServiceType.GOOGLE}
              badgeSVG={GooglePartnerSVG}
              iconHref={t('googleBadgeLink')}
            />
          }
        >
          <Content isMobile={isMobile} t={t} />
        </GoogleAdsPage>
      )}
    </>
  );
};
