import { useMemo } from 'react';
import { getScoreName } from 'src/components/facebook/grade/table/getScoreName';
import { ScoreName } from 'src/enums/ScoreName';
import { ICampaignGoals } from 'src/types/facebook/FacebookGrade';

type Props = {
  campaignGoals?: ICampaignGoals;
};

type ReturnType = {
  awarenessScoreName: ScoreName;
  considerationScoreName: ScoreName;
  conversionScoreName: ScoreName;
};

export const useCampaignScoreNames = ({ campaignGoals }: Props): ReturnType => {
  const { awareness, consideration, conversion } = campaignGoals ?? {};

  const awarenessScoreName = useMemo(
    () =>
      getScoreName({
        you: awareness?.engagementsRate,
        benchmark: awareness?.competitorEngagementsRate,
        difference: awareness?.engagementsRatePercentDiff,
      }),
    [awareness],
  );

  const considerationScoreName = useMemo(
    () =>
      getScoreName({
        you: consideration?.ctr,
        benchmark: consideration?.competitorCtr,
        difference: consideration?.ctrPercentDiff,
      }),
    [consideration],
  );

  const conversionScoreName = useMemo(
    () =>
      getScoreName({
        you: conversion?.ctr,
        benchmark: conversion?.competitorCtr,
        difference: conversion?.ctrPercentDiff,
      }),
    [conversion],
  );

  return { awarenessScoreName, considerationScoreName, conversionScoreName };
};
