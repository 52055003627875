import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { TypedGrid } from 'src/components/common/Grid';
import { StyledPageContent } from 'src/components/common/styled/StyledPageElements';
import { GridType } from 'src/enums/GridType';
import { useLocation, useParams } from 'react-router-dom';
import { useGoogleGrade } from 'src/hooks/google/useGoogleGrade';
import { Header } from 'src/components/googleAds/grade/header/Header';
import { useContext, useEffect } from 'react';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { HeaderActions } from 'src/components/common/grade/header/headerActions';
import { ServiceType } from 'src/enums/ServiceType';
import { Progress } from 'src/components/common/progress/Progress';
import { LoadingContext } from 'src/components/providers/loading';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import styled from 'styled-components';

const NavBarHeight = '64px';
const FooterDesktopHeight = '125px';

type IProps = {
  gridType: GridType;
  errord: boolean;
  isMobile: boolean;
  theme: Theme;
  isLoading: boolean;
};

const GetBackGroundBoxMinHeight = (isMobile: boolean, isLoading: boolean, errord: boolean): string => {
  const FooterMobileHeight = '277px';
  const PaddingDesktopHeight = '80px';
  const PaddingMobileHeight = '128px';
  const ErrorBannerHeightDesktop = '221px';
  const ErrorBannerHeightMobile = '472px';

  if (errord && isMobile) {
    return `calc(100vh - ${NavBarHeight} - ${FooterMobileHeight} - ${ErrorBannerHeightMobile} - ${PaddingMobileHeight})`;
  } else if (errord && !isMobile) {
    return `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight} - ${ErrorBannerHeightDesktop} - ${PaddingDesktopHeight})`;
  }

  if (isLoading && !isMobile) {
    return `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight})`;
  } else if (isLoading && isMobile) {
    return '100%';
  }

  if (!isLoading && !errord && isMobile) {
    return `calc(100vh - ${NavBarHeight} - ${FooterMobileHeight} - ${PaddingMobileHeight})`;
  } else if (!isLoading && !errord && !isMobile) {
    return `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight} - ${PaddingDesktopHeight})`;
  }

  return '100%';
};

const GetBackGroundBoxGradient = (gridType: GridType, theme: Theme): { background: string } => ({
  background:
    gridType === GridType.TAB
      ? `linear-gradient(
          294deg,
        #04009D 14.59%,
          ${theme.palette.primary.main} 77.31%,
          ${theme.palette.info.dark} 100%
      )`
      : `linear-gradient(
          142deg,
          ${theme.palette.info.dark} 0%,
          ${theme.palette.primary.main} 29.25%,
        #04009D 82.27%
      )`,
});

const GetBackGroundBoxPaddingY = (gridType: GridType, isMobile: boolean, theme: Theme): string => {
  if (gridType !== GridType.TAB && isMobile) {
    return theme.spacing(8);
  } else if (gridType !== GridType.TAB && !isMobile) {
    return theme.spacing(5);
  }
  return theme.spacing(0);
};

export const FlexBoxRowWithBackground = styled(FlexBoxRow)(
  ({ gridType, errord, isMobile, theme, isLoading }: IProps) => ({
    width: '100vw',
    paddingTop: GetBackGroundBoxPaddingY(gridType, isMobile, theme),
    paddingBottom: GetBackGroundBoxPaddingY(gridType, isMobile, theme),
    minHeight: gridType !== GridType.TAB ? GetBackGroundBoxMinHeight(isMobile, isLoading, errord) : '100%',
    ...GetBackGroundBoxGradient(gridType, theme),
  }),
);

export const StyledPageContentHeight = (
  gridType: GridType,
  isProgressBarComplete: boolean,
  isMobile: boolean,
): string => {
  if (gridType === GridType.TAB && !isProgressBarComplete && !isMobile) {
    return `calc(100vh - ${NavBarHeight} - 64px - 64px - ${FooterDesktopHeight})`;
  }
  return '100%';
};

export const StyledPageContentPT = (
  gridType: GridType,
  isProgressBarComplete: boolean,
  isMobile: boolean,
  theme: Theme,
): string => {
  if (gridType === GridType.TAB && isMobile) {
    return theme.spacing(2);
  } else if (gridType === GridType.TAB && !isMobile && !isProgressBarComplete) {
    return theme.spacing(8);
  } else if (gridType === GridType.TAB && !isMobile && isProgressBarComplete) {
    return theme.spacing(5);
  }
  return theme.spacing(0);
};

export const StyledPageContentPB = (gridType: GridType, isMobile: boolean, theme: Theme): string => {
  if (gridType === GridType.TAB && isMobile) {
    return theme.spacing(5);
  } else if (gridType === GridType.TAB && !isMobile) {
    return theme.spacing(8);
  }
  return theme.spacing(0);
};

export const StyledPageContentGap = (
  gridType: GridType,
  isProgressBarComplete: boolean,
  isMobile: boolean,
  theme: Theme,
): string => {
  if (gridType === GridType.TAB && !isProgressBarComplete && isMobile) {
    return theme.spacing(3);
  } else if (gridType === GridType.TAB && !isProgressBarComplete && !isMobile) {
    return theme.spacing(0);
  } else if (isMobile) {
    return theme.spacing(5);
  } else {
    return theme.spacing(6);
  }
};

export const FlexBoxRowWithBackgroundContainerGap = (gridType: GridType, isMobile: boolean): number => {
  if (gridType === GridType.TAB && isMobile) {
    return 2;
  } else if (gridType === GridType.TAB && !isMobile) {
    return 7;
  }
  return 0;
};

type PageContentProps = {
  children: JSX.Element | JSX.Element[];
};

export const PageContentSearch = ({ children }: PageContentProps): JSX.Element => {
  const { t } = useTranslation(['google', 'common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { UUID } = useParams();
  const { isProgressBarComplete, isProgressBarRunning, setApiComplete, setProgressBarComplete } =
    useContext(LoadingContext);
  const { grade, error } = useGoogleGrade(UUID);
  const location = useLocation();
  const errord = !!error || location.pathname.includes('/error');
  const isLoading = error === undefined && grade === undefined;

  // Reset loading context states
  useEffect(() => {
    setProgressBarComplete(false);
    setApiComplete(false);
  }, []);

  // set api-complete as grade is loaded now ( or error'd )
  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setApiComplete(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  return (
    <FlexBoxColumn gap={FlexBoxRowWithBackgroundContainerGap(GridType.TAB, isMobile)}>
      <FlexBoxRowWithBackground
        gridType={GridType.TAB}
        errord={errord}
        isMobile={isMobile}
        isLoading={isProgressBarRunning}
      >
        <StyledPageContent
          sx={{
            height: StyledPageContentHeight(GridType.TAB, isProgressBarComplete, isMobile),
            alignItems: 'center',
            alignSelf: 'flex-start',
            paddingTop: StyledPageContentPT(GridType.TAB, isProgressBarComplete, isMobile, theme),
            paddingBottom: StyledPageContentPB(GridType.TAB, isMobile, theme),
            gap: StyledPageContentGap(GridType.TAB, isProgressBarComplete, isMobile, theme),
          }}
        >
          <ResponsiveRow
            breakpoint={'sm'}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              py: isMobile ? 0 : 3,
              gap: 3,
            }}
          >
            <FlexBoxColumn sx={{ alignItems: 'flex-start', flex: '1 1 0' }} gap={1}>
              <Typography variant="h3" color="common.white">
                {t('title1')}
              </Typography>
              <Typography variant="h4" color="common.white" fontWeight={'600'}>
                {t('title2')}
              </Typography>
            </FlexBoxColumn>
            {isProgressBarComplete && (
              <Box sx={{ alignSelf: isMobile ? 'stretch' : 'center' }}>
                <HeaderActions googleAdType={GoogleAdType.SEARCH} />
              </Box>
            )}
          </ResponsiveRow>
          <FlexBoxColumn sx={{ alignSelf: 'stretch', flex: '1 0 0', pb: isProgressBarComplete ? 0 : 5 }}>
            {isProgressBarComplete ? (
              <FadeInTransition>
                <Header />
              </FadeInTransition>
            ) : (
              <FadeInTransition>
                <Progress serviceType={ServiceType.GOOGLE} />
              </FadeInTransition>
            )}
          </FlexBoxColumn>
        </StyledPageContent>
      </FlexBoxRowWithBackground>
      {isProgressBarComplete && (
        <StyledPageContent data-testid="pageContent" sx={{ justifyContent: 'flex-start' }}>
          <TypedGrid type={GridType.TAB}>{children}</TypedGrid>
        </StyledPageContent>
      )}
    </FlexBoxColumn>
  );
};
