import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { GridType } from 'src/enums/GridType';
import { Typography, useMediaQuery } from '@mui/material';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { MetricsAccordions, MetricsTabs } from 'src/components/googleAds/metrics/Metrics';
import { TFunction, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { SectionContent, GoogleGradeTabs } from 'src/components/googleAds/Grades';
import { PageTitle } from 'src/enums/PageTitle';
import { ServiceType } from 'src/enums/ServiceType';
import { useDriftChat } from 'src/hooks/useDriftChat';
import { useReport } from './useReport';
import { GoogleAdsNoSpend } from './NoSpend';
import { ReportLink } from 'src/components/common/ReportLink';
import { useReportLinksData } from 'src/hooks/common/useReportLinksData';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GradeAccordions as GoogleSearchAccordions } from 'src/components/common/grade/Accordions';
import { GoogleAdsScoreSection } from 'src/enums/google/ScoreSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import GooglePartnerSVG from 'src/assets/GooglePartner.svg';
import BadgeFooter from 'src/components/common/BadgeFooter';

interface HeadingProps {
  whiteSpace: 'pre' | 'normal';
  text: string;
  isMobile: boolean;
}

export const Heading = ({ whiteSpace, text, isMobile }: HeadingProps): JSX.Element => (
  <FadeInTransition triggerAfter={750}>
    <FlexBoxRow sx={{ mt: isMobile ? 5 : 7.5, mb: isMobile ? 2 : 3.75 }}>
      <Typography variant="h3" sx={{ whiteSpace, textAlign: 'center' }}>
        {text}
      </Typography>
    </FlexBoxRow>
  </FadeInTransition>
);

interface ContentProps {
  t: TFunction;
}

const Content = ({ t }: ContentProps): JSX.Element => {
  const reportLinksData = useReportLinksData();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return isMobile ? (
    <>
      <GoogleSearchAccordions
        defaultExpandedSection={GoogleAdsScoreSection.WASTED_SPEND}
        scoreSections={Object.values(GoogleAdsScoreSection)}
        renderSectionContent={(sectionName) => (
          <SectionContent sectionName={sectionName as GoogleAdsScoreSection} isMobile={true} />
        )}
      />
      <Heading whiteSpace="pre" text={t('metrics.deepDive')} isMobile={isMobile} />
      <MetricsAccordions />
      <Heading whiteSpace="pre" text={t('resultsByProduct')} isMobile={isMobile} />
      <FlexBoxColumn sx={{ gap: 2 }}>
        {reportLinksData.map((reportLinkData) => (
          <ReportLink
            adType={reportLinkData.adtype}
            url={reportLinkData.url}
            isMobile={isMobile}
            key={reportLinkData.pathName}
            scoreName={reportLinkData.scoreName}
            scoreValue={reportLinkData.scoreValue}
          />
        ))}
      </FlexBoxColumn>
    </>
  ) : (
    <>
      <GoogleGradeTabs />
      <Heading whiteSpace="normal" text={t('metrics.deepDive')} isMobile={isMobile} />
      <MetricsTabs />
      <Heading whiteSpace="normal" text={t('resultsByProduct')} isMobile={isMobile} />
      <FlexBoxColumn sx={{ gap: 2 }}>
        {reportLinksData.map((reportLinkData) => (
          <ReportLink
            adType={reportLinkData.adtype}
            url={reportLinkData.url}
            isMobile={isMobile}
            key={reportLinkData.pathName}
            scoreName={reportLinkData.scoreName}
            scoreValue={reportLinkData.scoreValue}
          />
        ))}
      </FlexBoxColumn>
    </>
  );
};

export const GoogleAdsReport = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { isZeroDollarsSpent } = useReport();
  const chatCode = useDriftChat();

  return (
    <>
      <Helmet>
        <title>
          {PageTitle.GOOGLE} | {'Report'}
        </title>
        <script>{chatCode}</script>
      </Helmet>
      {isZeroDollarsSpent && <GoogleAdsNoSpend />}
      {!isZeroDollarsSpent && (
        <GoogleAdsPage
          gridType={GridType.TAB}
          additionalFooter={
            <BadgeFooter
              i18nNameSpace={ServiceType.GOOGLE}
              badgeSVG={GooglePartnerSVG}
              iconHref={t('googleBadgeLink')}
            />
          }
        >
          <Content t={t} />
        </GoogleAdsPage>
      )}
    </>
  );
};
