import { Alert, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { OrionColors } from '../theme/orion/common/colors';
import CircleCheck from 'src/assets/CircleCheck.svg';

interface IProps {
  message: string;
  onClose: () => void;
}

export const AlertSnackbar: React.FC<IProps> = ({ message, onClose }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Alert
        severity="success"
        variant="standard"
        icon={<img src={CircleCheck} alt="CircleCheck" />}
        sx={{ borderLeftColor: OrionColors.green.darkBackground }}
        action={
          <Typography
            variant="button"
            component="a"
            sx={{ cursor: 'pointer', textDecoration: 'underline', textTransform: 'none' }}
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            Dismiss
          </Typography>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
