import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { Overview } from './Overview';
import { SummaryTable, SummaryTableMobile } from './SummaryTable';
import { KeyMetric } from './KeyMetric';
import { AdditionalMetrics } from './AdditionalMetrics';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { AdPerformanceType, AdPerformanceTypeOrder } from 'src/enums/facebook/AdPerformance';
import { IAdMetric } from 'src/types/facebook/FacebookGrade';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import FBAdPlaceholderImage from 'src/assets/FBAdPlaceholderImage.png';
import { OrionColors } from 'src/components/theme/orion/common/colors';

export const AdPerformance = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: undefined });

  return (
    <FlexBoxColumn sx={{ gap: 5, pt: 3, width: '100%' }}>
      <ResponsiveRow breakpoint={600} sx={{ width: '100%', justifyContent: 'space-between' }}>
        <BoldTypography variant="h4">{t('metrics.adPerformance.heading')}</BoldTypography>
        <Typography variant="h6" color={OrionColors.gray.mid} fontStyle="italic">
          {t('last30', { ns: 'common' })}
        </Typography>
      </ResponsiveRow>
      <Overview />
      {isMobile ? grade && <SummaryTableMobile grade={grade} /> : grade && <SummaryTable grade={grade} />}
      {grade?.adsPerformance?.adMetrics
        ?.sort(
          (a, b) =>
            AdPerformanceTypeOrder[a.adPerformanceType as AdPerformanceType] -
            AdPerformanceTypeOrder[b.adPerformanceType as AdPerformanceType],
        )
        .map((metric: IAdMetric) => (
          <ResponsiveRow breakpoint={600} sx={{ alignItems: 'flex-start', width: '100%' }} key={metric.adId}>
            <FlexBoxColumn gap={3} sx={{ width: '100%', alignItems: isMobile ? 'center' : 'flex-start' }}>
              <FlexBoxRow sx={{ width: '100%', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                <BoldTypography variant="h5">
                  {t(
                    `metrics.adPerformance.adStatus.${AdPerformanceType[`${metric.adPerformanceType}` as keyof typeof AdPerformanceType].toLowerCase()}`,
                  )}
                </BoldTypography>
              </FlexBoxRow>
              <ResponsiveRow
                breakpoint={600}
                sx={{
                  width: '100%',
                  gap: isMobile ? 0 : 10,
                  justifyContent: 'flex-start',
                  alignItems: 'space-around',
                }}
              >
                <FlexBoxColumn sx={{ p: isMobile ? 1 : 2, background: OrionColors.gray.light, flexBasis: '25%' }}>
                  {metric.previewUrl ? (
                    <img src={metric.previewUrl} alt="Ad-Preview" />
                  ) : (
                    <img src={FBAdPlaceholderImage} alt="Ad-Preview-Placeholder" />
                  )}
                </FlexBoxColumn>
                <FlexBoxColumn sx={{ flexBasis: '75%', gap: isMobile ? 2 : 5, justifyContent: 'space-between' }}>
                  <KeyMetric
                    ctr={metric.ctr}
                    competitorCtr={metric.competitorAvgCtr}
                    pctDiff={metric.ctrPercentDiff}
                    adId={metric.adId}
                  />
                  <AdditionalMetrics
                    key={metric.adId}
                    metrics={{
                      clicks: metric.clicks,
                      cpr: metric.cpr,
                      impressions: metric.impressions,
                      frequency: metric.frequency,
                    }}
                    currencySymbol={currencySymbol}
                  />
                </FlexBoxColumn>
              </ResponsiveRow>
            </FlexBoxColumn>
          </ResponsiveRow>
        ))}
    </FlexBoxColumn>
  );
};
