import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { ContentCardForErrors } from 'src/components/common/ContentCard';
import { GridType } from 'src/enums/GridType';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import ErrorFooter from 'src/components/googleAds/errors/ErrorFooter';
import { OrionColors } from '../../components/theme/orion/common/colors';
import { Email } from '../../components/common/metrics/Email';
import { useParams } from 'react-router-dom';
import { useOverallSpend } from '../../hooks/overall/useOverallSpend';
import { SpendRow } from '../../components/common/metrics/PerformanceMetrics';
import { FlexBoxColumn } from '../../components/common/FlexBoxColumn';
import { FlexBoxRow } from '../../components/common/FlexBoxRow';
import { OtherSpendRow } from '../../components/overall/metrics/OtherSpendRow';
import { ISpendDataGroup, spendDataGroups } from '../../components/overall/metrics/SpendBreakdown';
import { useOverallGrade } from '../../hooks/overall/useOverallGrade';

export const GoogleAdsNoSpend = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE, 'common']);
  const { UUID } = useParams();
  const { overallGrade } = useOverallGrade(UUID);
  const { spendData } = useOverallSpend(UUID);

  const hasOtherSpend = spendData?.otherSpend !== undefined && spendData.otherSpend > 0;

  return (
    <GoogleAdsPage gridType={GridType.FORM} additionalFooter={<ErrorFooter />}>
      <ContentCardForErrors title={t('error.title_nospend')} body={t('error.body_nospend')}>
        <>
          {overallGrade && spendData && hasOtherSpend && (
            <Box sx={{ mt: 4, width: '100%' }}>
              <SpendRow
                spendText={t('metrics.overallSpend.otherSpend')}
                spend={spendData.otherSpend!.toLocaleString(process.env.LOCALE, { minimumFractionDigits: 2 })}
                currencySymbol={overallGrade.currencySymbol}
              >
                <></>
              </SpendRow>
              <FlexBoxColumn
                sx={{
                  p: 3,
                  alignSelf: 'stretch',
                  gap: 1,
                }}
              >
                {spendDataGroups.map((group: ISpendDataGroup, index: number) => (
                  <OtherSpendRow
                    key={`other-spend-row-${index}`}
                    keys={group.data}
                    spendData={spendData}
                    currencySymbol={overallGrade.currencySymbol}
                    singleColumn={true}
                  />
                ))}
              </FlexBoxColumn>
              <FlexBoxRow sx={{ py: 4 }}>
                <Typography textAlign="center" variant="caption">
                  {t('errors.spendRange', { ns: 'common' })}
                </Typography>
              </FlexBoxRow>
            </Box>
          )}
        </>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          LinkComponent={Link}
          href="https://www.wordstream.com/learn"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          {t('error.button_nospend')}
        </Button>
        <Divider sx={{ width: '100%', marginY: 5, borderColor: OrionColors.gray.legacyTint }} />
        <Email />
      </ContentCardForErrors>
    </GoogleAdsPage>
  );
};
