import { useEffect, useState } from 'react';
import { useDisplayGradeScore } from 'src/hooks/display/useDisplayGradeScore';
import { useGGradeScore } from 'src/hooks/google/useGGradeScore';
import { useOverallGradeScore } from 'src/hooks/overall/userOverallGradeScore';
import { useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';

declare global {
  interface Date {
    addDays: (days: number) => Date;
  }
}

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
};

export const ReportDateRangeSearch = (): { dateRange: string } => {
  const date = useGGradeScore({ sectionName: undefined }).date;
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (!date) return;
    const previousDay = new Date(date).addDays(-1);
    const prior90Days = new Date(date).addDays(-91);
    const to = previousDay.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
    const from = prior90Days.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

    setDateRange(`${from} - ${to}`);
  }, [date]);

  return { dateRange };
};

export const ReportDateRangeDisplay = (): { dateRange: string } => {
  const date = useDisplayGradeScore({ sectionName: undefined }).date;
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (!date) return;
    const previousDay = new Date(date).addDays(-1);
    const prior90Days = new Date(date).addDays(-91);
    const to = previousDay.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
    const from = prior90Days.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

    setDateRange(`${from} - ${to}`);
  }, [date]);

  return { dateRange };
};

export const ReportDateRangeOverall = (): { dateRange: string } => {
  const date = useOverallGradeScore().date;
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (!date) return;
    const previousDay = new Date(date).addDays(-1);
    const prior90Days = new Date(date).addDays(-91);
    const to = previousDay.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
    const from = prior90Days.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

    setDateRange(`${from} - ${to}`);
  }, [date]);

  return { dateRange };
};

export const ReportDateRangeShopping = (): { dateRange: string } => {
  const date = useShoppingGradeScore({}).date;
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (!date) return;
    const previousDay = new Date(date).addDays(-1);
    const prior90Days = new Date(date).addDays(-91);
    const to = previousDay.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
    const from = prior90Days.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

    setDateRange(`${from} - ${to}`);
  }, [date]);

  return { dateRange };
};
