import { SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MetricsTab } from 'src/components/common/metrics/MetricsTab';
import { OverallMetricsTabName } from 'src/enums/display/MetricsTabName';
import { ServiceType } from 'src/enums/ServiceType';
import { useParams } from 'react-router-dom';
import { useOverallSpend } from 'src/hooks/overall/useOverallSpend';

type IProps = {
  onChange: (event: SyntheticEvent<Element, Event>, value: OverallMetricsTabName) => void;
};

type ReturnType = {
  TabList: JSX.Element[];
};

export const useTabList = ({ onChange }: IProps): ReturnType => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { UUID } = useParams();
  const { spendData, spendError } = useOverallSpend(UUID);
  let tabNames = Object.values(OverallMetricsTabName);

  if (spendError?.error || spendData?.totalSpend === 0) {
    tabNames = tabNames.filter((tab) => tab !== OverallMetricsTabName.SpendBreakdown);
  }

  const TabList = useMemo(() => {
    return tabNames.map((tabKey) => {
      return <MetricsTab name={t(`metrics.${tabKey}`)} value={tabKey} key={tabKey} onChange={() => onChange} />;
    }) as JSX.Element[];
  }, [tabNames]);

  return { TabList };
};
